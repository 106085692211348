import React from "react";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        // background: "#59005B",
        color: "#FFF",
        minHeight: "100vh",
      }}
    >
      {/* Contenido superior */}
     

      {/* Figura central */}
      <div style={{ textAlign: "center", margin: "20px 0" }}>
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/logoHome.png`} // Cambia la ruta por la figura correcta
          alt="Figura"
          style={{width:' 80%',   height: 'auto', marginTop: 40 }}
        />
      </div>

      {/* Botón de ingreso */}
      <div style={{ textAlign: "center", margin: "30px 0" }}>
        <Link
          to="/violencia"
          style={{
            textDecoration: "none",
            background: "#59005B",
            color: "#FFF",
            padding: "26px 48px",
            fontSize: "21px",
            borderRadius: "5px",
            height: "25px",
            width: "45%",
            display: "inline-block",
            fontFamily: "'Poppin', 'Helvetica', 'Arial', sans-serif",
            letterSpacing: 7
          }}
        >
          INGRESA
        </Link>
      </div>

      {/* Logos de organizaciones */}
      <div
        style={{
          background: "#FFF",
          color: "#000",
          padding: "20px 10px 80px 10px",
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
            flexWrap: "wrap",
            marginBottom: "30px",
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/REDFEM_H.png`} // Cambia esta ruta
            alt="RED-FEM"
            style={{ width: "60px", height: "60px"}}
          />
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/LAS DIGNAS_H.png`} // Cambia esta ruta
            alt="Las Dignas"
            style={{ width: "60px", height: "60px" }}
          />
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/LASMELIDAS_H.png`} // Cambia esta ruta
            alt="Las Mélidas"
            style={{ width: "60px", height: "60px" }}
          />
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/ORMUSA_H.png`} // Cambia esta ruta
            alt="ORMUSA"
            style={{ width: "60px", height: "60px" }}
          />
        </div>
        <p style={{textAlign:'center', fontSize: "12px", lineHeight: "1.5", margin: "0 10px" }}>
          El contenido de esta aplicación es responsabilidad exclusiva de la Red
          Feminista Frente a la Violencia contra las Mujeres (RED FEM), y en
          ningún caso debe considerarse que refleja los puntos de vista de las
          agencias y organismos donantes.
        </p>
      </div>
    </div>
  );
};

export default Home;
