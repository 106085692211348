import React from "react";
import { useNavigate } from "react-router-dom";
import data from "../data";
import {
  AppBar,
  Toolbar,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Box,
  Divider,
  createTheme,
  ThemeProvider,
} from "@mui/material";

const theme = createTheme({
  palette: {
    primary: { main: "#4b224b" },
    secondary: { main: "#7D0084" },
    background: { default: "#f5f5f5" },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});

const Leyes = () => {
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={theme}>
      <div style={{ height: "100%" }}>
        {/* AppBar */}
        <AppBar position="sticky" sx={{ backgroundColor: theme.palette.primary.main }}>
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1, fontFamily: "'Poppin', 'Helvetica', 'Arial', sans-serif",   letterSpacing: 4 }}>
            LEYES Y DECRETOS
            </Typography>
          </Toolbar>
        </AppBar>

        {/* Contenido */}
        <Box sx={{ padding: "20px", paddingBottom: "80px" }}>
          <List>
            {data.leyes_y_decretos.map((ley, index) => (
              <React.Fragment key={index}>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => navigate(`/leyes/${index}`)}
                    sx={{
                      borderRadius: "8px",
                      marginBottom: "15px",
                      backgroundColor: theme.palette.background.default,
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                      transition: "background-color 0.3s ease",
                      "&:hover": {
                        backgroundColor: "#e0e0e0",
                      },
                    }}
                  >
                    <ListItemText
                      primary={ley.titulo}
                      primaryTypographyProps={{
                        fontSize: "18px",
                        fontWeight: "500",
                        color:"#595959",
                        fontFamily: "'Poppins', sans-serif",
                      }}
                    />
                  </ListItemButton>
                </ListItem>
                {index < data.leyes_y_decretos.length - 1 && (
                  <Divider variant="middle" />
                )}
              </React.Fragment>
            ))}
          </List>
        </Box>
      </div>
    </ThemeProvider>
  );
};

export default Leyes;
