import React from "react";
import { useNavigate } from "react-router-dom";
import { AppBar, Toolbar, IconButton, Typography, Box, Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowBack"; // Flecha para navegar

const Informacion = () => {
  const navigate = useNavigate();



  return (
    <div style={{ minHeight: "100vh", backgroundColor: "#f7f7f7" }}>
      {/* AppBar */}
      <AppBar position="sticky" style={{ backgroundColor: "#59005B" }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => navigate(-1)}>
            <ArrowForwardIcon />
          </IconButton>
             <Typography
                        variant="h6"
                        sx={{
                          flexGrow: 1
                          , fontFamily: "'Poppin', 'Helvetica', 'Arial', sans-serif",   letterSpacing: 4 
                        }}
                      >
            PARA TU INFORMACIÓN
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Contenido principal */}
      <div
        style={{
          padding: "20px",
          paddingBottom: "80px", // Espacio para la BottomAppBar
          color: "white", // Texto en blanco
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/CONTENIDO.png`}
            alt="Icono Nos Apoyan"
            style={{
              width: "100%",
              height: "auto",
              marginBottom: "16px",
            }}
          />
          <Button
            variant="contained"
            style={{
              backgroundColor: "#59005B",
              color: "white",
              fontSize: "1.2rem",
              padding: "12px 20px",
              borderRadius: "12px",
              marginTop: "20px",
            }}
            onClick={() => navigate('/informate')}
          >
            INFÓRMATE
          </Button>
        </Box>
      </div>
    </div>
  );
};

export default Informacion;

