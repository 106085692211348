import React from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Divider,
  Box,
  createTheme,
  ThemeProvider,
} from "@mui/material";

const theme = createTheme({
  palette: {
    primary: { main: "#4b224b" },
    secondary: { main: "#C2CD23" },
    background: { default: "#f5f5f5" }, // Fondo oscuro original
    text: { primary: "#FFFFFF" },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});

const Info = () => {
  const navigate = useNavigate();

  const routes = [
   
    {
      name: "Vínculos de Interés",
      path: "/vinculos",
      image: "/assets/images/vinculos.png",
    },
    { name: "Archivos Multimedia", path: "/multimedia", image: "/assets/images/multimedia.png" },
    {
      name: "Para tu Información",
      path: "/informacion",
      image: "/assets/images/informacion.png",
    },
   
    // { name: "Nos Apoyan", path: "/apoya", image: "/assets/images/apoyan.png" },
    // {
    //   name: "¿Cómo recibiste el servicio?",
    //   path: "/apoya",
    //   image: "/assets/images/apoyan.png",
    // },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{  minHeight: "100vh" }}>
        {/* AppBar */}
        <AppBar position="sticky" sx={{ backgroundColor: theme.palette.primary.main }}>
          <Toolbar>
            <Typography
              variant="h6"
              sx={{
                flexGrow: 1
                , fontFamily: "'Poppin', 'Helvetica', 'Arial', sans-serif",   letterSpacing: 4 
              }}
            >
             MÁS INFORMACIÓN
            </Typography>
          </Toolbar>
        </AppBar>

        {/* Contenido Principal */}
        <Box sx={{ padding: "20px" }}>
          <List>
            {routes.map((route, index) => (
              <React.Fragment key={index}>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => navigate(route.path)}
                    sx={{
                      borderRadius: "8px",
                      marginBottom: "10px",
                      backgroundColor: "rgba(255, 255, 255, 0.1)", // Fondo semitransparente
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                      transition: "background-color 0.3s ease",
                      "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.2)",
                      },
                    }}
                  >
                    {/* Imagen a la izquierda */}
                    <ListItemAvatar>
                      <Avatar
                        src={process.env.PUBLIC_URL + route.image}
                        alt={route.name}
                        sx={{
                          width: "40px",
                          height: "40px",
                          backgroundColor: theme.palette.primary.main,
                        }}
                      />
                    </ListItemAvatar>
                    {/* Texto */}
                    <ListItemText
                      primary={route.name}
                      primaryTypographyProps={{
                        sx: {
                          fontFamily: "'Poppins', sans-serif",
                          fontWeight: "500",
                          color: theme.palette.text.primary,
                        },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
                {/* Divider */}
                {index < routes.length - 1 && (
                  <Divider sx={{ backgroundColor: "rgba(255, 255, 255, 0.2)", marginY: "10px" }} />
                )}
              </React.Fragment>
            ))}
          </List>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Info;
