import React from "react";
import { Typography, Box, Container } from "@mui/material";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
const LeyOne = ({ titulo, url_audio, url_pdf }) => (
  <Box
    style={{
      marginBottom: "20px",
    }}
  >
    <Container>
    <Typography variant="h6" gutterBottom  sx={{
                color: '#FFF',
                fontFamily: "'Poppins', sans-serif",
                fontWeight: "bold",
              }}>
      {titulo}
    </Typography>
    </Container>        
    <Box sx={{ marginTop: "20px" }}>     
      <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js`}>
        <div style={{ height: "100%", overflow: "auto" }}>
          <Viewer fileUrl={process.env.PUBLIC_URL + url_pdf} />
        </div>
      </Worker>
    </Box>
  </Box>
);

export default LeyOne;
