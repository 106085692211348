import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import data from "../data";
import LeyOne from "../components/LeyOne";
import { AppBar, Toolbar, IconButton, Typography, Box, createTheme, ThemeProvider } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// Mapeo de componentes según su nombre en `url_codigo_componente`
const componentMap = {
  LeyOne,
};

const theme = createTheme({
  palette: {
    primary: { main: "#4b224b" },
    error: { main: "#FF0000" },
    background: { default: "#f5f5f5" },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});

const LeyDetalle = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const ley = data.leyes_y_decretos[id];

  if (!ley) {
    return (
      <ThemeProvider theme={theme}>
        <div>
          {/* AppBar */}
          <AppBar position="sticky" sx={{ backgroundColor: theme.palette.primary.main }}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={() => navigate(-1)}>
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h6" sx={{ flexGrow: 1, textAlign: "center" }}>
                Ley no encontrada
              </Typography>
            </Toolbar>
          </AppBar>
          {/* Mensaje de error */}
          <Box sx={{ padding: "20px", textAlign: "center" }}>
            <Typography
              variant="h6"
              sx={{
                color: theme.palette.error.main,
                fontFamily: "'Poppins', sans-serif",
                fontWeight: "bold",
              }}
            >
              Ley no encontrada
            </Typography>
          </Box>
        </div>
      </ThemeProvider>
    );
  }

  const LeyComponent = componentMap[ley.url_codigo_componente];

  return (
    <ThemeProvider theme={theme}>
      <div>
        {/* AppBar */}
        <AppBar position="sticky" sx={{ backgroundColor: theme.palette.primary.main }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={() => navigate(-1)}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" sx={{ flexGrow: 1, fontFamily: "'Poppin', 'Helvetica', 'Arial', sans-serif",   letterSpacing: 4  }}>
            LECTURA DE LEY
            </Typography>
          </Toolbar>
        </AppBar>

        {/* Contenido principal */}
        <Box sx={{ padding: "20px 0px 60px 0px" }}>
          {LeyComponent ? (
            <LeyComponent
              titulo={ley.titulo}
              url_audio={ley.url_audio}
              url_pdf={ley.url_pdf}
            />
          ) : (
            <Typography
              variant="h6"
              sx={{
                color: theme.palette.error.main,
                fontFamily: "'Poppins', sans-serif",
                fontWeight: "bold",
              }}
            >
              Componente no disponible.
            </Typography>
          )}
        </Box>
      </div>
    </ThemeProvider>
  );
};

export default LeyDetalle;
