import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import data from "../data";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Card,
  CardContent,
  Grid,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const theme = createTheme({
  palette: {
    primary: { main: "#4b224b" },
    secondary: { main: "#7D0084" },
    background: { default: "#f5f5f5" },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});

const Ejemplos = () => {
  const { id } = useParams();
  const tipo = data.tipos_de_violencia[id];
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={theme}>
      <div>
        {/* AppBar */}
        <AppBar position="sticky" sx={{ backgroundColor: theme.palette.primary.main }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="Regresar a la página anterior"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" sx={{ flexGrow: 1 , fontFamily: "'Poppin', 'Helvetica', 'Arial', sans-serif",   letterSpacing: 4 }}>
             {tipo.titulo}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ padding: "20px" }}>
          <Typography
            variant="h4"
            sx={{
              color: '#FFF',
              fontWeight: "bold",
              fontFamily: "'Poppins', sans-serif",
            }}
          >
            Ejemplos
          </Typography>
        </Box>
        {/* Contenido */}
        <Box sx={{ padding: "20px", marginBottom: "60px" }}>
          <Grid container spacing={4}>
            {tipo.ejemplos.map((ej, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  sx={{
                    padding: "0px",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    borderRadius: "8px",
                  }}
                >
                  <CardContent sx={{ padding: "20px" }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "'Poppins', sans-serif",
                        color: "#4b224b",
                        lineHeight: "1.5",
                      }}
                    >
                      {ej.texto}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </div>
    </ThemeProvider>
  );
};

export default Ejemplos;
