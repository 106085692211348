import React from "react";
import { useNavigate } from "react-router-dom";
import data from "../data";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Card,
  CardContent,
  CardMedia,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import Grid from '@mui/material/Grid2';
const theme = createTheme({
  palette: {
    primary: { main: "#4b224b" },
    secondary: { main: "#C2CD23" },
    background: { default: "#f5f5f5" },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});

const Servicios = () => {
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={theme}>
      <div style={{ height: "100%" }}>
        {/* AppBar */}
        <AppBar position="sticky" sx={{ backgroundColor: theme.palette.primary.main }}>
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1, fontFamily: "'Poppin', 'Helvetica', 'Arial', sans-serif",   letterSpacing: 4  }}>
            SERVICIOS DE ATENCIÓN 
            </Typography>
          </Toolbar>
        </AppBar>

        {/* Contenido */}
        <Box sx={{ padding: "20px 20px 80px 20px", marginBottom: "60px" }}>
          <Box sx={{ textAlign: "center", marginBottom: "20px" }}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/Apoyo.png`}
              alt="Servicios"
              style={{
                maxWidth: "100%",
                height: "200px",
              }}
            />
          </Box>

          <Grid container spacing={4}>
            {data.servicios_de_atencion.map((servicio, index) => (
              <Grid size={{ xs: 6, sm:6, md: 4 }}item   key={index}>
                <Card
                  sx={{
                    cursor: "pointer",
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    textAlign: "center",
                    padding: "20px",
                  }}
                  onClick={() => navigate(`/servicios/${index}`)}
                >
                  <CardMedia
                    component="img"
                    image={process.env.PUBLIC_URL + servicio.logo}
                    alt={servicio.titulo}
                    sx={{
                      width: "100%",
                      height: "100%",
                      margin: "0 auto",
                      objectFit: "contain",
                    }}
                  />
                  {/* <CardContent>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "'Poppins', sans-serif",
                        color: theme.palette.secondary.main,
                        fontWeight: "bold",
                        lineHeight: "1.2",
                      }}
                    >
                      {servicio.titulo}
                    </Typography>
                  </CardContent> */}
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </div>
    </ThemeProvider>
  );
};

export default Servicios;
