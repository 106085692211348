import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { BottomNavigation, BottomNavigationAction, Box, Paper } from "@mui/material";
import { Female, LocationOn, SupportAgent, Gavel, Menu } from "@mui/icons-material";

const Layout = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  // Determinar la pestaña activa en función de la ruta
  const getActiveTab = () => {
    if (location.pathname.startsWith("/violencia")) return "/violencia";
    if (location.pathname === "/oficinas") return "/oficinas";
    if (location.pathname.startsWith("/servicios")) return "/servicios";
    if (location.pathname.startsWith("/leyes")) return "/leyes";
    if (location.pathname === "/info") return "/info";
    return "/";
  };

  const navItems = [
    { label: "Tipos de Violencia", icon: <Female />, path: "/violencia" },
    { label: "Oficinas de Atención", icon: <LocationOn />, path: "/oficinas" },
    { label: "Servicios de Atención", icon: <SupportAgent />, path: "/servicios" },
    { label: "Leyes y Decretos", icon: <Gavel />, path: "/leyes" },
    { label: "Más Información", icon: <Menu />, path: "/info" },
  ];

  const activeTab = getActiveTab();

  return (
    <Box display="flex" flexDirection="column" height="100vh">
      {/* Contenido de la vista */}
      <Box flex="1" overflow="auto">
        {children}
      </Box>

      {/* Bottom Navigation */}
      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={activeTab}
          onChange={(event, newValue) => navigate(newValue)}
        >
          {navItems.map((item) => (
            <BottomNavigationAction
              key={item.label}
              icon={item.icon}
              value={item.path}
              sx={{
                color: activeTab === item.path ? "#59005B" : "inherit",
                "& .MuiSvgIcon-root": {
                  color: activeTab === item.path ? "#59005B" : "inherit",
                },
              }}
            />
          ))}
        </BottomNavigation>
      </Paper>
    </Box>
  );
};

export default Layout;
