const data = {
  tipos_de_violencia: [
    {
      titulo: "VIOLENCIA ECONÓMICA",
      texto: "Es toda acción u omisión de la persona agresora, que afecta la supervivencia económica de la mujer, la cual se manifiesta a través de actos encaminados a limitar, controlar o impedir el ingreso de sus percepciones económicas.",
      url_imagen: "/assets/images/violencia/Economica.png",
      modalidades: [
        {
          titulo: "Violencia Comunitaria",
          texto: "Toda acción u omisión abusiva que a partir de actos individuales o colectivos transgreden los derechos fundamentales de la mujer y propician su degradación, discriminación, marginación o exclusión y ocurren en la comunidad, colonia, espacios colectivos como las Adesco, Sindicato, Junta directiva vecinal u otras.",
          url_icono: "violenciaComunitaria.png",
        },
        {
          titulo: "Violencia Institucional",
          texto: "Es toda acción u omisión abusiva de cualquier servidor público, que discrimine o tenga como fin dilatar, obstaculizar o impedir el goce y disfrute de los derechos y libertades fundamentales de las mujeres así como, la que pretenda obstaculizar u obstaculice el acceso de las mujeres al disfrute de políticas públicas destinadas a prevenir, atender, investigar, sancionar y erradicar las manifestaciones, tipos y modalidades de violencia conceptualizadas en esta ley y puede ocurrir en oficinas públicas como alcaldías, ministerios o servicios como los de salud.",
          url_icono: "violenciaInstitucional.png",
        },
        {
          titulo: "Violencia Laboral",
          texto: "Son acciones u omisiones contra las mujeres ejercidas en forma repetida y que se mantiene en el tiempo en los centros de trabajo público o privados, que constituyan agresiones físicas o psicológicas atentatorias a su integridad, dignidad personal y profesional, que obstaculicen su acceso al empleo, ascenso o estabilidad en el mismo, o que quebranten el derecho a igual salario por igual trabajo.",
          url_icono: "violenciaLaboral.png",
        },
        {
          titulo: "Violencia Familiar",
          texto: "Es cualquier acción u omisión, directa o indirecta que cause daño, sufrimiento físico, sexual o muerte a las mujeres en el marco de las relaciones de familia como cónyuges, excónyuges, convivientes, exconvivientes, ascendientes, descendientes, parientes, colaterales por consanguinidad, afinidad, adopción, los de sujetos a tutela o guarda, incluso las relaciones interpersonales como las del noviazgo.",
          url_icono: "violenciaFamiliar.png",
        },
        {
          titulo: "Violencia Digital",
          texto: "Es toda conducta contra las mujeres que sea cometida, asistida o agravada en parte o en su totalidad por el uso de las tecnologías de la información y la comunicación (TIC), como teléfonos móviles y teléfonos inteligentes, Internet, plataformas de redes sociales o correo electrónico, dispositivos de seguimiento de geolocalización, drones y dispositivos de grabación no conectados a Internet e Inteligencia Artificial (IA) y ocurre en la dimensión digital, pudiendo afectar cualquier derecho de las mujeres.",
          url_icono: "violenciaDigital.png",
        },
      ],
      ejemplos: [
        { texto: "Negar la cuota alimenticia." },
        { texto: "No aportar dinero al hogar." },
        { texto: "Retener el salario." },
        { texto: "Privar de las necesidades básicas (alimentación, salud, vestuario, etc.)." },
        { texto: "Cargar con deudas." },
        { texto: "Prohibir trabajar fuera." },
        { texto: "Limitar o impedir la administración o control de sus ingresos." },
        { texto: "Negar el acceso a ingresos." },
        { texto: "Otras que pueden afectar la estabilidad económica." },
      ],
    },
    {
      titulo: "VIOLENCIA FEMINICIDA",
      texto: "Es la forma extrema de violencia de género contra las mujeres, producto de la violación de sus derechos humanos, en los ámbitos público y privado, conformada por el conjunto de conductas misóginas que conllevan a la impunidad social o del Estado, pudiendo culminar en feminicidio y en otras formas de muerte violenta de mujeres.",
      url_imagen: "/assets/images/violencia/Feminicida.png",
      modalidades: [
        {
          titulo: "Violencia Comunitaria",
          texto: "Toda acción u omisión abusiva que a partir de actos individuales o colectivos transgreden los derechos fundamentales de la mujer y propician su degradación, discriminación, marginación o exclusión y ocurren en la comunidad, colonia, espacios colectivos como las Adesco, Sindicato, Junta directiva vecinal u otras.",
          url_icono: "violenciaComunitaria.png",
        },
        {
          titulo: "Violencia Institucional",
          texto: "Es toda acción u omisión abusiva de cualquier servidor público, que discrimine o tenga como fin dilatar, obstaculizar o impedir el goce y disfrute de los derechos y libertades fundamentales de las mujeres así como, la que pretenda obstaculizar u obstaculice el acceso de las mujeres al disfrute de políticas públicas destinadas a prevenir, atender, investigar, sancionar y erradicar las manifestaciones, tipos y modalidades de violencia conceptualizadas en esta ley y puede ocurrir en oficinas públicas como alcaldías, ministerios o servicios como los de salud.",
          url_icono: "violenciaInstitucional.png",
        },
        {
          titulo: "Violencia Laboral",
          texto: "Son acciones u omisiones contra las mujeres ejercidas en forma repetida y que se mantiene en el tiempo en los centros de trabajo público o privados, que constituyan agresiones físicas o psicológicas atentatorias a su integridad, dignidad personal y profesional, que obstaculicen su acceso al empleo, ascenso o estabilidad en el mismo, o que quebranten el derecho a igual salario por igual trabajo.",
          url_icono: "violenciaLaboral.png",
        },
        {
          titulo: "Violencia Familiar",
          texto: "Es cualquier acción u omisión, directa o indirecta que cause daño, sufrimiento físico, sexual o muerte a las mujeres en el marco de las relaciones de familia como cónyuges, excónyuges, convivientes, exconvivientes, ascendientes, descendientes, parientes, colaterales por consanguinidad, afinidad, adopción, los de sujetos a tutela o guarda, incluso las relaciones interpersonales como las del noviazgo.",
          url_icono: "violenciaFamiliar.png",
        },
        {
          titulo: "Violencia Digital",
          texto: "Es toda conducta contra las mujeres que sea cometida, asistida o agravada en parte o en su totalidad por el uso de las tecnologías de la información y la comunicación (TIC), como teléfonos móviles y teléfonos inteligentes, Internet, plataformas de redes sociales o correo electrónico, dispositivos de seguimiento de geolocalización, drones y dispositivos de grabación no conectados a Internet e Inteligencia Artificial (IA) y ocurre en la dimensión digital, pudiendo afectar cualquier derecho de las mujeres.",
          url_icono: "violenciaDigital.png",
        },
      ],
      ejemplos: [
        { texto: "Muertes maternas prevenibles." },
        { texto: "Muertes violentas de mujeres en manos de sus parejas ej. ex-parejas, familiares o personas del entorno de confianza." },
        { texto: "Muertes por desnutrición causada por la negación de acceso a recursos para alimentación." },
        { texto: "Suicidios vinculados con embarazos no deseados en adolescentes." },
        { texto: "Muerte de mujeres a manos de hombres extraños, ya sea de forma individual o bajo crimen organizado." },
        { texto: "La desaparición de las mujeres." },
      ],
    },
    {
      titulo: "VIOLENCIA FÍSICA",
      texto: "Es toda conducta que directa o indirectamente, está dirigida a ocasionar daño o sufrimiento físico contra la mujer, con resultado o riesgo de producir lesión física o daño, ejercida por quien sea o haya sido su cónyuge o por quien esté o haya estado ligado a ella por análoga relación de afectividad, aun sin convivencia. Asimismo, tendrán la consideración de actos de violencia física contra la mujer, los ejercidos por la persona agresora en su entorno familiar, social o laboral.",
      url_imagen: "/assets/images/violencia/Fisica.png",
      modalidades: [
        {
          titulo: "Violencia Comunitaria",
          texto: "Toda acción u omisión abusiva que a partir de actos individuales o colectivos transgreden los derechos fundamentales de la mujer y propician su degradación, discriminación, marginación o exclusión y ocurren en la comunidad, colonia, espacios colectivos como las Adesco, Sindicato, Junta directiva vecinal u otras.",
          url_icono: "violenciaComunitaria.png",
        },
        {
          titulo: "Violencia Institucional",
          texto: "Es toda acción u omisión abusiva de cualquier servidor público, que discrimine o tenga como fin dilatar, obstaculizar o impedir el goce y disfrute de los derechos y libertades fundamentales de las mujeres así como, la que pretenda obstaculizar u obstaculice el acceso de las mujeres al disfrute de políticas públicas destinadas a prevenir, atender, investigar, sancionar y erradicar las manifestaciones, tipos y modalidades de violencia conceptualizadas en esta ley y puede ocurrir en oficinas públicas como alcaldías, ministerios o servicios como los de salud.",
          url_icono: "violenciaInstitucional.png",
        },
        {
          titulo: "Violencia Laboral",
          texto: "Son acciones u omisiones contra las mujeres ejercidas en forma repetida y que se mantiene en el tiempo en los centros de trabajo público o privados, que constituyan agresiones físicas o psicológicas atentatorias a su integridad, dignidad personal y profesional, que obstaculicen su acceso al empleo, ascenso o estabilidad en el mismo, o que quebranten el derecho a igual salario por igual trabajo.",
          url_icono: "violenciaLaboral.png",
        },
        {
          titulo: "Violencia Familiar",
          texto: "Es cualquier acción u omisión, directa o indirecta que cause daño, sufrimiento físico, sexual o muerte a las mujeres en el marco de las relaciones de familia como cónyuges, excónyuges, convivientes, exconvivientes, ascendientes, descendientes, parientes, colaterales por consanguinidad, afinidad, adopción, los de sujetos a tutela o guarda, incluso las relaciones interpersonales como las del noviazgo.",
          url_icono: "violenciaFamiliar.png",
        },
        {
          titulo: "Violencia Digital",
          texto: "Es toda conducta contra las mujeres que sea cometida, asistida o agravada en parte o en su totalidad por el uso de las tecnologías de la información y la comunicación (TIC), como teléfonos móviles y teléfonos inteligentes, Internet, plataformas de redes sociales o correo electrónico, dispositivos de seguimiento de geolocalización, drones y dispositivos de grabación no conectados a Internet e Inteligencia Artificial (IA) y ocurre en la dimensión digital, pudiendo afectar cualquier derecho de las mujeres.",
          url_icono: "violenciaDigital.png",
        },
      ],
      ejemplos: [
        { texto: "Golpes, patadas o puñetazos." },
        { texto: "Empujones, heridas o contusiones." },
        { texto: "Fracturas o hematonas." },
        { texto: "Discapacidad." },
        { texto: "Agresiones con armas de fuego." },
        { texto: "Quemaduras o pellizcos." },
      ],
    },
    {
      titulo: "VIOLENCIA PSICOLÓGICA",
      texto: "Es toda conducta directa o indirecta que ocasione daño emocional, disminuya el autoestima, perjudique o perturbe el sano desarrollo de la mujer; ya sea que esta conducta sea verbal, que produzca en la mujer desvalorización o sufrimiento, mediante amenazas, exigencia de obediencia o sumisión, coerción, culpabilización, así como daño reputacional a la imagen y dignidad de las mujeres en el entorno digital.",
      url_imagen: "/assets/images/violencia/Psicologica.png",
      modalidades: [
        {
          titulo: "Violencia Comunitaria",
          texto: "Toda acción u omisión abusiva que a partir de actos individuales o colectivos transgreden los derechos fundamentales de la mujer y propician su degradación, discriminación, marginación o exclusión y ocurren en la comunidad, colonia, espacios colectivos como las Adesco, Sindicato, Junta directiva vecinal u otras.",
          url_icono: "violenciaComunitaria.png",
        },
        {
          titulo: "Violencia Institucional",
          texto: "Es toda acción u omisión abusiva de cualquier servidor público, que discrimine o tenga como fin dilatar, obstaculizar o impedir el goce y disfrute de los derechos y libertades fundamentales de las mujeres así como, la que pretenda obstaculizar u obstaculice el acceso de las mujeres al disfrute de políticas públicas destinadas a prevenir, atender, investigar, sancionar y erradicar las manifestaciones, tipos y modalidades de violencia conceptualizadas en esta ley y puede ocurrir en oficinas públicas como alcaldías, ministerios o servicios como los de salud.",
          url_icono: "violenciaInstitucional.png",
        },
        {
          titulo: "Violencia Laboral",
          texto: "Son acciones u omisiones contra las mujeres ejercidas en forma repetida y que se mantiene en el tiempo en los centros de trabajo público o privados, que constituyan agresiones físicas o psicológicas atentatorias a su integridad, dignidad personal y profesional, que obstaculicen su acceso al empleo, ascenso o estabilidad en el mismo, o que quebranten el derecho a igual salario por igual trabajo.",
          url_icono: "violenciaLaboral.png",
        },
        {
          titulo: "Violencia Familiar",
          texto: "Es cualquier acción u omisión, directa o indirecta que cause daño, sufrimiento físico, sexual o muerte a las mujeres en el marco de las relaciones de familia como cónyuges, excónyuges, convivientes, exconvivientes, ascendientes, descendientes, parientes, colaterales por consanguinidad, afinidad, adopción, los de sujetos a tutela o guarda, incluso las relaciones interpersonales como las del noviazgo.",
          url_icono: "violenciaFamiliar.png",
        },
        {
          titulo: "Violencia Digital",
          texto: "Es toda conducta contra las mujeres que sea cometida, asistida o agravada en parte o en su totalidad por el uso de las tecnologías de la información y la comunicación (TIC), como teléfonos móviles y teléfonos inteligentes, Internet, plataformas de redes sociales o correo electrónico, dispositivos de seguimiento de geolocalización, drones y dispositivos de grabación no conectados a Internet e Inteligencia Artificial (IA) y ocurre en la dimensión digital, pudiendo afectar cualquier derecho de las mujeres.",
          url_icono: "violenciaDigital.png",
        },
      ],
      ejemplos: [
        { texto: "Gritos, insultos o apodos." },
        { texto: "Negar el afecto." },
        { texto: "Ignorar, manipular, chantajear o amenazar." },
        { texto: "Ofernder, humillar o exigir obediencia." },
        { texto: "Prohibición de salir." },
        { texto: "Hostigar" },
        { texto: "Controlar el arreglo personal." },
        { texto: "Perseguir." },
        { texto: "Prohibir tener amistades." },
        { texto: "Prohibir relacionarse con su familia" },
        { texto: "Prohibir estudiar." },
        { texto: "Amenazar con quitar hijos/as." },
        { texto: "Criticar el aspecto físico" },
        { texto: "Intimidar" },
      ],
    },
    {
      titulo: "VIOLENCIA PATRIMONIAL",
      texto: "Son las acciones, omisiones o conductas que afectan la libre disposición del patrimonio de la mujer; incluyéndose los daños a los bienes comunes o propios mediante la transformación, sustracción, destrucción, distracción, daño, perdida, limitación, retención de objetos, documentos personales, bienes, valores y derechos patrimoniales. En consecuente, serán nulos los actos de alzamiento, simulación de enajenación de los bienes muebles o inmuebles; cualquiera que sea el régimen patrimonial del matrimonio, incluyéndose el de la unión no patrimonial pudiendo ocurrir por medios digitales como mensajes.",
      url_imagen: "/assets/images/violencia/Patrimonial.png",
      modalidades: [
        {
          titulo: "Violencia Comunitaria",
          texto: "Toda acción u omisión abusiva que a partir de actos individuales o colectivos transgreden los derechos fundamentales de la mujer y propician su degradación, discriminación, marginación o exclusión y ocurren en la comunidad, colonia, espacios colectivos como las Adesco, Sindicato, Junta directiva vecinal u otras.",
          url_icono: "violenciaComunitaria.png",
        },
        {
          titulo: "Violencia Institucional",
          texto: "Es toda acción u omisión abusiva de cualquier servidor público, que discrimine o tenga como fin dilatar, obstaculizar o impedir el goce y disfrute de los derechos y libertades fundamentales de las mujeres así como, la que pretenda obstaculizar u obstaculice el acceso de las mujeres al disfrute de políticas públicas destinadas a prevenir, atender, investigar, sancionar y erradicar las manifestaciones, tipos y modalidades de violencia conceptualizadas en esta ley y puede ocurrir en oficinas públicas como alcaldías, ministerios o servicios como los de salud.",
          url_icono: "violenciaInstitucional.png",
        },
        {
          titulo: "Violencia Laboral",
          texto: "Son acciones u omisiones contra las mujeres ejercidas en forma repetida y que se mantiene en el tiempo en los centros de trabajo público o privados, que constituyan agresiones físicas o psicológicas atentatorias a su integridad, dignidad personal y profesional, que obstaculicen su acceso al empleo, ascenso o estabilidad en el mismo, o que quebranten el derecho a igual salario por igual trabajo.",
          url_icono: "violenciaLaboral.png",
        },
        {
          titulo: "Violencia Familiar",
          texto: "Es cualquier acción u omisión, directa o indirecta que cause daño, sufrimiento físico, sexual o muerte a las mujeres en el marco de las relaciones de familia como cónyuges, excónyuges, convivientes, exconvivientes, ascendientes, descendientes, parientes, colaterales por consanguinidad, afinidad, adopción, los de sujetos a tutela o guarda, incluso las relaciones interpersonales como las del noviazgo.",
          url_icono: "violenciaFamiliar.png",
        },
        {
          titulo: "Violencia Digital",
          texto: "Es toda conducta contra las mujeres que sea cometida, asistida o agravada en parte o en su totalidad por el uso de las tecnologías de la información y la comunicación (TIC), como teléfonos móviles y teléfonos inteligentes, Internet, plataformas de redes sociales o correo electrónico, dispositivos de seguimiento de geolocalización, drones y dispositivos de grabación no conectados a Internet e Inteligencia Artificial (IA) y ocurre en la dimensión digital, pudiendo afectar cualquier derecho de las mujeres.",
          url_icono: "violenciaDigital.png",
        },
      ],
      ejemplos: [
        { texto: "Apropiarse o destruir artículos u objetos de la mujer o del hogar." },
        { texto: "Retención/destrucción de documentos personales u otros bienes." },
        { texto: "Obligar a salir de la casa de habitación." },
        { texto: "Obligar a dar claves de cuentas bancarias." },
        { texto: "Estafas por medios digitales." },
      ],
    },
    {
      titulo: "VIOLENCIA SEXUAL",
      texto: "Es toda conducta que amenace o vulnere el derecho de la mujer a decidir voluntariamente su vida sexual, comprendida en esta no solo el acto sexual sino toda forma de contacto o acceso sexual, genital (incluyendo el chantaje o extorsión sexual por medios digitales) o no genital, con independencia de que las la persona agresora guarde o no relación conyugal, de pareja, social, laboral, efectiva o de parentesco con la mujer víctima.",
      url_imagen: "/assets/images/violencia/Sexual.png",
      modalidades: [
        {
          titulo: "Violencia Comunitaria",
          texto: "Toda acción u omisión abusiva que a partir de actos individuales o colectivos transgreden los derechos fundamentales de la mujer y propician su degradación, discriminación, marginación o exclusión y ocurren en la comunidad, colonia, espacios colectivos como las Adesco, Sindicato, Junta directiva vecinal u otras.",
          url_icono: "violenciaComunitaria.png",
        },
        {
          titulo: "Violencia Institucional",
          texto: "Es toda acción u omisión abusiva de cualquier servidor público, que discrimine o tenga como fin dilatar, obstaculizar o impedir el goce y disfrute de los derechos y libertades fundamentales de las mujeres así como, la que pretenda obstaculizar u obstaculice el acceso de las mujeres al disfrute de políticas públicas destinadas a prevenir, atender, investigar, sancionar y erradicar las manifestaciones, tipos y modalidades de violencia conceptualizadas en esta ley y puede ocurrir en oficinas públicas como alcaldías, ministerios o servicios como los de salud.",
          url_icono: "violenciaInstitucional.png",
        },
        {
          titulo: "Violencia Laboral",
          texto: "Son acciones u omisiones contra las mujeres ejercidas en forma repetida y que se mantiene en el tiempo en los centros de trabajo público o privados, que constituyan agresiones físicas o psicológicas atentatorias a su integridad, dignidad personal y profesional, que obstaculicen su acceso al empleo, ascenso o estabilidad en el mismo, o que quebranten el derecho a igual salario por igual trabajo.",
          url_icono: "violenciaLaboral.png",
        },
        {
          titulo: "Violencia Familiar",
          texto: "Es cualquier acción u omisión, directa o indirecta que cause daño, sufrimiento físico, sexual o muerte a las mujeres en el marco de las relaciones de familia como cónyuges, excónyuges, convivientes, exconvivientes, ascendientes, descendientes, parientes, colaterales por consanguinidad, afinidad, adopción, los de sujetos a tutela o guarda, incluso las relaciones interpersonales como las del noviazgo.",
          url_icono: "violenciaFamiliar.png",
        },
        {
          titulo: "Violencia Digital",
          texto: "Es toda conducta contra las mujeres que sea cometida, asistida o agravada en parte o en su totalidad por el uso de las tecnologías de la información y la comunicación (TIC), como teléfonos móviles y teléfonos inteligentes, Internet, plataformas de redes sociales o correo electrónico, dispositivos de seguimiento de geolocalización, drones y dispositivos de grabación no conectados a Internet e Inteligencia Artificial (IA) y ocurre en la dimensión digital, pudiendo afectar cualquier derecho de las mujeres.",
          url_icono: "violenciaDigital.png",
        },      ],
      ejemplos: [
        { texto: "Tocamientos; contacto o acceso sexual, genital o no genital," },
        { texto: "Acoso sexual." },
        { texto: "Violación." },
        { texto: "Exposición de genitales." },
        { texto: "Pornografía." },
        { texto: "Expresar palabras obscenas" },
        { texto: "Pedir imágenes de partes del cuerpo o desnudez por mensajes." },
        { texto: "Introducir objetos," },
        { texto: "Obligar a ver pornografía." },
        { texto: "Obligar a presenciar actos sexuales." },
        { texto: "Obligar a tocamientos." },
        { texto: "Obligar a realizar actos sexuales con terceros." },
        { texto: "Obligar a mantener actos sexuales mientras está enferma." },
      ],
    },
    {
      titulo: "VIOLENCIA SIMBÓLICA",
      texto: "Son mensajes, valores, iconos o signos que transmiten y reproducen relaciones de dominación, desigualdad y discriminación en las relaciones sociales que se establecen entre las personas y naturalizan la subordinación de la mujer en la sociedad, pudiendo realizarse a través de medios digitales, por mensajes, redes sociales o sitios web.",
      url_imagen: "/assets/images/violencia/Simbolica.png",
      modalidades: [
        {
          titulo: "Violencia Comunitaria",
          texto: "Toda acción u omisión abusiva que a partir de actos individuales o colectivos transgreden los derechos fundamentales de la mujer y propician su degradación, discriminación, marginación o exclusión y ocurren en la comunidad, colonia, espacios colectivos como las Adesco, Sindicato, Junta directiva vecinal u otras.",
          url_icono: "violenciaComunitaria.png",
        },
        {
          titulo: "Violencia Institucional",
          texto: "Es toda acción u omisión abusiva de cualquier servidor público, que discrimine o tenga como fin dilatar, obstaculizar o impedir el goce y disfrute de los derechos y libertades fundamentales de las mujeres así como, la que pretenda obstaculizar u obstaculice el acceso de las mujeres al disfrute de políticas públicas destinadas a prevenir, atender, investigar, sancionar y erradicar las manifestaciones, tipos y modalidades de violencia conceptualizadas en esta ley y puede ocurrir en oficinas públicas como alcaldías, ministerios o servicios como los de salud.",
          url_icono: "violenciaInstitucional.png",
        },
        {
          titulo: "Violencia Laboral",
          texto: "Son acciones u omisiones contra las mujeres ejercidas en forma repetida y que se mantiene en el tiempo en los centros de trabajo público o privados, que constituyan agresiones físicas o psicológicas atentatorias a su integridad, dignidad personal y profesional, que obstaculicen su acceso al empleo, ascenso o estabilidad en el mismo, o que quebranten el derecho a igual salario por igual trabajo.",
          url_icono: "violenciaLaboral.png",
        },
        {
          titulo: "Violencia Familiar",
          texto: "Es cualquier acción u omisión, directa o indirecta que cause daño, sufrimiento físico, sexual o muerte a las mujeres en el marco de las relaciones de familia como cónyuges, excónyuges, convivientes, exconvivientes, ascendientes, descendientes, parientes, colaterales por consanguinidad, afinidad, adopción, los de sujetos a tutela o guarda, incluso las relaciones interpersonales como las del noviazgo.",
          url_icono: "violenciaFamiliar.png",
        },
        {
          titulo: "Violencia Digital",
          texto: "Es toda conducta contra las mujeres que sea cometida, asistida o agravada en parte o en su totalidad por el uso de las tecnologías de la información y la comunicación (TIC), como teléfonos móviles y teléfonos inteligentes, Internet, plataformas de redes sociales o correo electrónico, dispositivos de seguimiento de geolocalización, drones y dispositivos de grabación no conectados a Internet e Inteligencia Artificial (IA) y ocurre en la dimensión digital, pudiendo afectar cualquier derecho de las mujeres.",
          url_icono: "violenciaDigital.png",
        },
      ],
      ejemplos: [
        { texto: "Música, pintura, películas, novelas, que contienen mensajes de sumisión." },
        { texto: "Celos o violencia." },
        { texto: "Publicidad con imágenes que cosifican a las mujeres y usan sus cuerpos para venta de productos." },
        { texto: "Publicaciones de textos o imágenes en redes sociales y celulares que afectan la imagen de las mujeres." },
        { texto: "Divulgar mensajes o imágenes íntimas que afectan la imagen de la mujer." },
      ],
    },
    {
      titulo: "VIOLENCIA DIGITAL",
      texto: "Son todas las conductas de violencia contra las mujeres, cometida, facilitada, asistida o agravada en parte o en su totalidad a través del uso de tecnologías de la información y la comunicación, como teléfonos móviles y teléfonos inteligentes, Internet, plataformas de redes sociales o correo electrónico, dispositivos de seguimiento de geolocalización, drones y dispositivos de grabación no conectados a Internet e Inteligencia Artificial (IA) o en entornos digitales como sitios web, plataformas, ya sea de forma organizada o no organizada por personas del entorno de confianza o no de las mujeres.",
      url_imagen: "/assets/images/violencia/Digital.png",
      modalidades: [
        {
          titulo: "Violencia Comunitaria",
          texto: "Toda acción u omisión abusiva que a partir de actos individuales o colectivos transgreden los derechos fundamentales de la mujer y propician su degradación, discriminación, marginación o exclusión y ocurren en la comunidad, colonia, espacios colectivos como las Adesco, Sindicato, Junta directiva vecinal u otras.",
          url_icono: "violenciaComunitaria.png",
        },
        {
          titulo: "Violencia Institucional",
          texto: "Es toda acción u omisión abusiva de cualquier servidor público, que discrimine o tenga como fin dilatar, obstaculizar o impedir el goce y disfrute de los derechos y libertades fundamentales de las mujeres así como, la que pretenda obstaculizar u obstaculice el acceso de las mujeres al disfrute de políticas públicas destinadas a prevenir, atender, investigar, sancionar y erradicar las manifestaciones, tipos y modalidades de violencia conceptualizadas en esta ley y puede ocurrir en oficinas públicas como alcaldías, ministerios o servicios como los de salud.",
          url_icono: "violenciaInstitucional.png",
        },
        {
          titulo: "Violencia Laboral",
          texto: "Son acciones u omisiones contra las mujeres ejercidas en forma repetida y que se mantiene en el tiempo en los centros de trabajo público o privados, que constituyan agresiones físicas o psicológicas atentatorias a su integridad, dignidad personal y profesional, que obstaculicen su acceso al empleo, ascenso o estabilidad en el mismo, o que quebranten el derecho a igual salario por igual trabajo.",
          url_icono: "violenciaLaboral.png",
        },
        {
          titulo: "Violencia Familiar",
          texto: "Es cualquier acción u omisión, directa o indirecta que cause daño, sufrimiento físico, sexual o muerte a las mujeres en el marco de las relaciones de familia como cónyuges, excónyuges, convivientes, exconvivientes, ascendientes, descendientes, parientes, colaterales por consanguinidad, afinidad, adopción, los de sujetos a tutela o guarda, incluso las relaciones interpersonales como las del noviazgo.",
          url_icono: "violenciaFamiliar.png",
        },
        {
          titulo: "Violencia Digital",
          texto: "Es toda conducta contra las mujeres que sea cometida, asistida o agravada en parte o en su totalidad por el uso de las tecnologías de la información y la comunicación (TIC), como teléfonos móviles y teléfonos inteligentes, Internet, plataformas de redes sociales o correo electrónico, dispositivos de seguimiento de geolocalización, drones y dispositivos de grabación no conectados a Internet e Inteligencia Artificial (IA) y ocurre en la dimensión digital, pudiendo afectar cualquier derecho de las mujeres.",
          url_icono: "violenciaDigital.png",
        },
      ],
      ejemplos: [
        { texto: "Que te pidan contraseña de tus cuentas de redes o correo electrónico." },
        { texto: "Que te pidan enviar fotografías tuyas sin que tú lo quieras." },
        { texto: "Que te insulten, amenacen o te desprestigien en redes sociales o chats grupales." },
        { texto: "Que modifiquen tu imagen, tu voz o entorno sin tu consentimiento." },
        { texto: "Que te coloquen dispositivos o aplicaciones que identifiquen tu ubicación o expongan tus datos." },
      ],
    },
    {
      titulo: "VIOLENCIA POLÍTICA",
      texto: "Acciones u omisiones contra las mujeres, realizadas de forma directa o indirecta por razón de género, que causen daño individual o colectivo y que tienen por objeto menoscabar o anular el reconocimiento, goce o ejercicio de sus derechos políticos y civiles, en cualquier ámbito de la vida política. Los ámbitos de la vida política comprenden el ejercicio de los derechos políticos, tales como derecho de organización, participación en procesos electorales y en órganos de dirección, así como la participación en el desarrollo rural y urbano.",
      url_imagen: "/assets/images/violencia/Politica.png",
      modalidades: [
        {
          titulo: "Violencia Comunitaria",
          texto: "Toda acción u omisión abusiva que a partir de actos individuales o colectivos transgreden los derechos fundamentales de la mujer y propician su degradación, discriminación, marginación o exclusión y ocurren en la comunidad, colonia, espacios colectivos como las Adesco, Sindicato, Junta directiva vecinal u otras.",
          url_icono: "violenciaComunitaria.png",
        },
        {
          titulo: "Violencia Institucional",
          texto: "Es toda acción u omisión abusiva de cualquier servidor público, que discrimine o tenga como fin dilatar, obstaculizar o impedir el goce y disfrute de los derechos y libertades fundamentales de las mujeres así como, la que pretenda obstaculizar u obstaculice el acceso de las mujeres al disfrute de políticas públicas destinadas a prevenir, atender, investigar, sancionar y erradicar las manifestaciones, tipos y modalidades de violencia conceptualizadas en esta ley y puede ocurrir en oficinas públicas como alcaldías, ministerios o servicios como los de salud.",
          url_icono: "violenciaInstitucional.png",
        },
        {
          titulo: "Violencia Laboral",
          texto: "Son acciones u omisiones contra las mujeres ejercidas en forma repetida y que se mantiene en el tiempo en los centros de trabajo público o privados, que constituyan agresiones físicas o psicológicas atentatorias a su integridad, dignidad personal y profesional, que obstaculicen su acceso al empleo, ascenso o estabilidad en el mismo, o que quebranten el derecho a igual salario por igual trabajo.",
          url_icono: "violenciaLaboral.png",
        },
        {
          titulo: "Violencia Familiar",
          texto: "Es cualquier acción u omisión, directa o indirecta que cause daño, sufrimiento físico, sexual o muerte a las mujeres en el marco de las relaciones de familia como cónyuges, excónyuges, convivientes, exconvivientes, ascendientes, descendientes, parientes, colaterales por consanguinidad, afinidad, adopción, los de sujetos a tutela o guarda, incluso las relaciones interpersonales como las del noviazgo.",
          url_icono: "violenciaFamiliar.png",
        },
        {
          titulo: "Violencia Digital",
          texto: "Es toda conducta contra las mujeres que sea cometida, asistida o agravada en parte o en su totalidad por el uso de las tecnologías de la información y la comunicación (TIC), como teléfonos móviles y teléfonos inteligentes, Internet, plataformas de redes sociales o correo electrónico, dispositivos de seguimiento de geolocalización, drones y dispositivos de grabación no conectados a Internet e Inteligencia Artificial (IA) y ocurre en la dimensión digital, pudiendo afectar cualquier derecho de las mujeres.",
          url_icono: "violenciaDigital.png",
        },
      ],
      ejemplos: [
        { texto: "Ataques y descalificaciones a mujeres que participan en la política con campañas de desprestigio por su género en lugar de por su desempeño." },
        { texto: "Exclusión de espacios de decisión, las mujeres son relegadas a posiciones simbólicas o no se les permite ocupar cargos claves dentro de sus partidos." },
        { texto: "Ataques sexistas, amenazas y comentarios misóginos que buscan desincentivar su participación en la política." },
        { texto: "Se subestima sus capacidades políticas por ser mujeres para limitar su participación y liderazgo." },
        { texto: "Estigmatización por su entorno social, que afecta su vida personal y profesional." },
        { texto: "Ataques digitales para desprestigiar su imagen y credibilidad." },
      ],
    },
  ],
  servicios_de_atencion: [
    {
      titulo: "SALUD",
      logo: "/assets/images/servicios/salud.png",
      items: [
        {
          id: 1,
          title: 'UIAEM CIUDAD BARRIOS',
          time: '24/7',
          phone: 26841500,
          direcction: '6ª. Av. Norte, Bo. Roma, Hospital Nacional General "Monseñor Oscar Arnulfo Romero y Galdámez", Ciudad Barios',
          Icon: 'MINSAL.jpg',
          mapURL: "https://maps.google.com/maps/place/Hospital+Nacional+De+Ciudad+Barrios/@13.766542,-88.2683314,19z/data=!4m5!3m4!1s0x8f64da4b033c8b91:0x228aabf686a621a8!8m2!3d13.7669665!4d-88.2682157",
          coordinate: {
            latitude: 13.767014,
            longitude: -88.102023,
          },
          tiposAtencion: `Salud`,
        },
        {
          id: 2,
          title: 'UIAEM ZACATECOLUCA',
          time: '24/7',
          phone: 23340190,
          direcction: 'Final Av. Juan Manuel Rodríguez, Calle al Volcán, Hospital Nacional “Santa Teresa”, Zacatecoluca.',
          Icon: 'MINSAL.jpg',
          mapURL: "https://maps.google.com/maps/place/Hospital+Nacional+Santa+Teresa/@13.5176091,-88.8709862,17z/data=!4m5!3m4!1s0x8f7cac9f5e9e7e51:0xe1d961b716d36c86!8m2!3d13.5176039!4d-88.8687975",
          coordinate: {
            latitude: 13.517823,
            longitude: -88.868390,
          },
          tiposAtencion: `Salud`,
        },
        {
          id: 3,
          title: 'UIAEM HOSPITAL DE LA MUJER',
          time: '24/7',
          phone: 25239500,
          direcction: 'Final 25 Avenida Sur y Calle Francisco Menéndez, Barrio Santa Anita, Hospital Nacional de la Mujer, San Salvador.',
          Icon: 'MINSAL.jpg',
          mapURL: "https://maps.google.com/maps/place/13%C2%B040'15.7%22N+89%C2%B016'41.5%22W/@13.6710278,-89.2787416,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.671029!4d-89.2781932",
          coordinate: {
            latitude: 13.671029,
            longitude: -89.278193,
          },
          tiposAtencion: `Salud`,
        },

        {
          id: 4,
          title: 'UIAEM HOSPITAL SAN JUAN DE DIOS, SAN MIGUEL',
          time: '24/7',
          phone: 26843000,
          direcction: 'Final 11 C. Pte.. Y 23 Av. Sur Colonia Ciudad Jardín, Hospital Nacional Regional “San Juan de Dios” San Miguel ',
          Icon: 'MINSAL.jpg',
          mapURL: "https://goo.gl/maps/jLBi6oi8sENJ7QKG6",
          coordinate: {
            latitude: 13.474493,
            longitude: -88.192952,
          },
          tiposAtencion: `Salud`,
        },
        {
          id: 5,
          title: 'UIAEM HOSPITAL "DR JORGE MAZZINI", SONSONATE',
          time: '24/7',
          phone: 24510200,
          direcction: 'Calle Alberto Masferrer Pte #3-1. Hospital Nacional General "Dr. Jorge Mazzini Villacorta" Sonsonate ',
          Icon: 'MINSAL.jpg',
          mapURL: "https://goo.gl/maps/p8sc8YxHey3ZUYVp9",
          coordinate: {
            latitude: 13.722899,
            longitude: -89.729483,
          },
          tiposAtencion: `Salud`,
        },
        {
          id: 6,
          title: 'UIAEM HOSPITAL NACIONAL SAN JUAN DE DIOS, SANTA ANA',
          time: '24/7',
          phone: 24359500,
          direcction: 'Final 13 Av. Sur No.1, Santa Ana. Hospital Nacional Regional “San Juan de Dios” de Santa Ana. ',
          Icon: 'MINSAL.jpg',
          mapURL: "https://goo.gl/maps/Jzv57uSvbgJ9NkD26",
          coordinate: {
            latitude: 13.992190,
            longitude: -89.551194,
          },
          tiposAtencion: `Salud`,
        },
        {
          id: 7,
          title: 'UIAEM HOSPITAL DE CHALCHUAPA, CHALCHUAPA',
          time: '24/7',
          phone: 24440217,
          direcction: 'Final Av. 2 de abril Nte. Hospital Nacional General Chalchuapa',
          Icon: 'MINSAL.jpg',
          mapURL: "https://goo.gl/maps/u9u8gWFm5D6UGfrW6",
          coordinate: {
            latitude: 13.989313,
            longitude: -89.678495,
          },
          tiposAtencion: `Salud`,
        },
        {
          id: 8,
          title: 'UIAEM HOSPITAL NACIONAL, COJUTEPEQUE',
          time: '24/7',
          phone: 29912200,
          direcction: 'Kilómetro 33 Carretera Panamerica Barrio El Calvario, Hospital Nacional “Nuestra Señora de Fátima”. Cojutepeque ',
          Icon: 'MINSAL.jpg',
          mapURL: "https://goo.gl/maps/D96GPCvP8nBNw1vT7",
          coordinate: {
            latitude: 13.726128,
            longitude: -88.942147,
          },
          tiposAtencion: `Salud`,
        },
        {
          id: 9,
          title: 'UIAEM HOSPITAL NACIONAL, SAN VICENTE',
          time: '24/7',
          phone: 23930267,
          direcction: '2ª. Av. Sur No. 23, Hospital Nacional General  "Santa Gertrudis", San Vicente',
          Icon: 'MINSAL.jpg',
          mapURL: "https://goo.gl/maps/bsQGnEnkoay6ojph9",
          coordinate: {
            latitude: 13.641217,
            longitude: -88.783838,
          },
          tiposAtencion: `Salud`,
        },
        {
          id: 10,
          title: 'UIAEM HOSPITAL NACIONAL SIQUIATRICO, SOYAPANGO',
          time: '24/7',
          phone: 23270200,
          direcction: 'Calle La Fuente, Ctón Venecia, Hospital Nacional General  y de Psiquiatría “Dr. José Molina Martínez”, Soyapango',
          Icon: 'MINSAL.jpg',
          mapURL: "https://goo.gl/maps/3N5JxAzgycTg5pat5",
          coordinate: {
            latitude: 13.714787,
            longitude: -89.139706,
          },
          tiposAtencion: `Salud`,
        },
        {
          id: 11,
          title: 'UIAEM HOSPITAL ZACAMIL ',
          time: '24/7',
          phone: 25945000,
          direcction: 'Urb. José Simeón Cañas, Col. Zacamil, Hospital Nacional “ Dr. Juan Jose Fernández” , Mejicanos',
          Icon: 'MINSAL.jpg',
          mapURL: "https://goo.gl/maps/j5DeNfAPbVkjkEBG9",
          coordinate: {
            latitude: 13.729168,
            longitude: -89.207230,
          },
          tiposAtencion: `Salud`,
        },
        {
          id: 12,
          title: 'UIAEM HOSPITAL SAN BARTOLO, ILPANGO',
          time: '24/7',
          phone: 22013100,
          direcction: 'Final C. Francisco Menéndez, contiguo Zona Franca San Bartolo, Hospital Nacional General "Enf. Angélica Vidal de Najarro", Ilopango',
          Icon: 'MINSAL.jpg',
          mapURL: "https://goo.gl/maps/YonvxkTkr1vqtx9c8",
          coordinate: {
            latitude: 13.705145,
            longitude: -89.105579,
          },
          tiposAtencion: `Salud`,
        },
        {
          id: 13,
          title: 'UIAEM HOSPITAL LA UNION',
          time: '24/7',
          phone: 26044104,
          direcction: '1ª. C. Ote y 9ª Av. Nte. No. 8, Barrio Concepción, Hospital Nacional General La Unión ',
          Icon: 'MINSAL.jpg',
          mapURL: "https://goo.gl/maps/28uh1ZQjx9TDCtGs9",
          coordinate: {
            latitude: 13.347503,
            longitude: -87.875909,
          },
          tiposAtencion: `Salud`,
        },
        {
          id: 14,
          title: 'UIAEM HOSPITAL SAN FRANCISCO GOTERA',
          time: '24/7',
          phone: 26457100,
          direcction: 'Final Av. Thomson Norte, Barrio La Cruz, Hospital Nacional General  "Dr. Héctor Antonio Hernández Flores", San Francisco Gotera ',
          Icon: 'MINSAL.jpg',
          mapURL: "https://goo.gl/maps/MZpSTs8aDwxg4F3m7",
          coordinate: {
            latitude: 13.698033,
            longitude: -88.102142,
          },
          tiposAtencion: `Salud`,
        },
        {
          id: 15,
          title: 'UIAEM HOSPITAL NACIONAL, USULUTAN',
          time: '24/7',
          phone: 26338800,
          direcction: 'Final Calle Federico Penado, salida a San Salvador, Hospital Nacional San Pedro de Usulutan.',
          Icon: 'MINSAL.jpg',
          mapURL: "https://goo.gl/maps/a1vX5GsxVEtKNJEk7",
          coordinate: {
            latitude: 13.343610,
            longitude: -88.449821,
          },
          tiposAtencion: `Salud`,
        },
        {
          id: 16,
          title: 'UIAEM HOSPITAL NACIONAL, JIQUILISCO',
          time: '24/7',
          phone: 26338800,
          direcction: '1ª. Av. Sur, Calle a Puerto Avalos, Cantón Roquinete, Hospital Nacional de Jiquilisco ',
          Icon: 'MINSAL.jpg',
          mapURL: "https://goo.gl/maps/18pNJU6VjJqwxbgw7",
          coordinate: {
            latitude: 13.319563,
            longitude: -88.571930,
          },
          tiposAtencion: `Salud`,
        },
        {
          id: 17,
          title: 'UIAEM HOSPITAL SAN RAFAEL, SANTA TECLA',
          time: '24/7',
          phone: 25239500,
          direcction: '4ª. C Ote No. 9-2. Santa Tecla,  Hospital Nacional General “San Rafael”, Santa Tecla ',
          Icon: 'MINSAL.jpg',
          mapURL: "https://goo.gl/maps/UCDNe8VMhBJXLZP19",
          coordinate: {
            latitude: 13.671197,
            longitude: -89.278237,
          },
          tiposAtencion: `Salud`,
        },
        {
          id: 18,
          title: 'Unidad de Atención Preconcepcional en la Unidad Comunitaria de Salud Familiar Especializada en el municipio de Olocuilta. ',
          time: '7:30AM - 3:30PM LUN-VIER',
          phone: 23306006,
          direcction: 'Barrio El Calvario, Av. Dr. Demetrio Bernal, Municipio de OLOCUILTA',
          Icon: 'MINSAL.jpg',
          mapURL: "https://maps.google.com/maps/place/Alcald%C3%ADa+Municipal+de+Olocuilta/@13.5691909,-89.1181725,19z/data=!4m5!3m4!1s0x0:0x6a74a0e6c409162!8m2!3d13.5695849!4d-89.118253",
          coordinate: {
            latitude: 13.569654,
            longitude: -89.118248,
          },
          tiposAtencion: `Salud`,
        },
      ],
    },
    {
      titulo: "ATENCIÓN LEGAL",
      logo: "/assets/images/servicios/atencionLegal.png",
      items: [
        {
          id: 1,
          title: 'FGR SONSONATE',
          time: '24/7',
          phone: 28913107,
          direcction: 'Hacienda Las Delicias, Edificio Los Portales, Km. 67, Carretera hacia Acajutla, a 500 metros del Colegio San Francisco, Sonsonate',
          Icon: 'FGR.jpg',
          mapURL: "https://maps.google.com/maps/place/Fiscalia+General+De+La+Republica/@13.7037589,-89.7345068,683m/data=!3m2!1e3!4b1!4m5!3m4!1s0x8f62b7a224adba23:0x1918997d24eb0e6!8m2!3d13.7037589!4d-89.7327266",
          coordinate: {
            latitude: 13.703813,
            longitude: -89.7326416,
          },
          tiposAtencion: `Atención Legal`,
        },
        {
          id: 2,
          title: 'FGR SANTA ANA',
          time: '24/7',
          phone: 28913004,
          direcction: '8ª Calle Poniente y Avenida José Matías Delgado Norte. Santa Ana.',
          Icon: 'FGR.jpg',
          mapURL: "https://maps.google.com/maps/place/13%C2%B043'41.5%22N+89%C2%B042'48.7%22W/@13.7281944,-89.7157165,17z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.7281988!4d-89.713526",
          coordinate: {
            latitude: 13.728199,
            longitude: -89.713526,
          },
          tiposAtencion: `Atención Legal`,
        },
        {
          id: 3,
          title: 'FGR SAN MIGUEL',
          time: '24/7',
          phone: 27912052,
          direcction: 'Barrio Concepción, Entre 15 y 17 Calle Oriente No 804, (Costado sur Centro de Gobierno). San Miguel.',
          Icon: 'FGR.jpg',
          mapURL: "https://maps.google.com/maps/place/13%C2%B028'24.3%22N+88%C2%B010'16.0%22W/@13.4734167,-88.1716583,171m/data=!3m2!1e3!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.4734038!4d-88.1711137",
          coordinate: {
            latitude: 13.473404,
            longitude: -88.171114,
          },
          tiposAtencion: `Atención Legal`,
        },
        {
          id: 4,
          title: 'FGR ZARAGOZA',
          time: '24/7',
          phone: 29908000,
          direcction: 'Centro Comercial Plaza Zaragoza, 3ª Calle Oriente, Entre Av. Monseñor Oscar Arnulfo Romero y Primera Ave. España, Zaragoza, La Libertad',
          Icon: 'FGR.jpg',
          mapURL: "https://maps.google.com/maps/place/Fiscal%C3%ADa+General+de+la+Rep%C3%BAblica;+Oficina+La+Libertad/@13.5908791,-89.2886142,85m/data=!3m1!1e3!4m5!3m4!1s0x8f632c55c6950b4f:0x3cd82c5bd5c6255c!8m2!3d13.5908686!4d-89.2885172",
          coordinate: {
            latitude: 13.590962,
            longitude: -89.288509,
          },
          tiposAtencion: `Atención Legal`,
        },
        {
          id: 5,
          title: 'FGR ANTIGUO CUSCATLAN',
          time: '24/7',
          phone: 25937210,
          direcction: 'Colonia y Av La Sultana, Edificio film No. G-12, Antiguo Cuscatlán. La Libertad. Por la UCA y el Super Selectos',
          Icon: 'FGR.jpg',
          mapURL: "https://maps.google.com/maps/place/Fiscal%C3%ADa+General+de+la+Rep%C3%BAblica/@13.680435,-89.2404176,341m/data=!3m1!1e3!4m5!3m4!1s0x8f6331d325656785:0x47df8a1057ea7b06!8m2!3d13.680573!4d-89.2399597",
          coordinate: {
            latitude: 13.680671,
            longitude: -89.239879,
          },
          tiposAtencion: `Atención Legal`,
        },
        
        {
          id: 6,
          title: 'PGR AHUACHAPAN',
          time: '7:30AM - 3:30PM LUN-VIER',
          phone: 24130838,
          direcction: '3a. C. Ote., No. 2-2, Barrio San José, Ahuachapán.',
          Icon: 'PGR.jpg',
          mapURL: "https://maps.google.com/maps/place/13%C2%B055'06.0%22N+89%C2%B050'45.5%22W/@13.9183333,-89.8465194,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.9183436!4d-89.8459832",
          coordinate: {
            latitude: 13.918344,
            longitude: -89.845983,
          },
          tiposAtencion: `Atención Legal`,
        },
        {
          id: 7,
          title: 'PGR SONZACATE',
          time: '8:00AM - 4:00PM LUN-VIER',
          phone: 24503354,
          direcction: 'Colonia San Francisco, 4ta. calle oriente, casa 22, Dos Cuadras Al Poniente de FGR de Sonzacate. Sonsonate',
          Icon: 'PGR.jpg',
          mapURL: "https://maps.google.com/maps/place/Procuraduria+General+De+La+Republica+Sonsonate/@13.7279383,-89.7157469,17z/data=!4m5!3m4!1s0x8f62b8247fed7b69:0xf1f3ec403f8ac60e!8m2!3d13.7279383!4d-89.7135582",
          coordinate: {
            latitude: 13.727988,
            longitude: -89.713561,
          },
          tiposAtencion: `Atención Legal`,
        },

        {
          id: 8,
          title: 'PGR SANTA ANA',
          time: '8:00AM - 4:00PM LUN-VIER',
          phone: 24479487,
          direcction: 'Av. Fray Felipe de Jesús Moraga Norte, Entre Calle Libertad Poniente y 4º Calle Poniente #2, Santa Ana.',
          Icon: 'PGR.jpg',
          mapURL: "https://maps.google.com/maps/place/Procuraduria+General+de+la+Republica/@13.9962792,-89.5638329,19z/data=!3m1!4b1!4m5!3m4!1s0x8f62e8b0ba1f24bb:0x9fe92800be9bf15f!8m2!3d13.9962792!4d-89.5632857",
          coordinate: {
            latitude: 13.996344,
            longitude: -89.563203,
          },
          tiposAtencion: `Atención Legal`,
        },
        {
          id: 9,
          title: 'PGR CHALATENANGO',
          time: '8:00AM - 4:00PM LUN-VIER',
          phone: 23352527,
          direcction: 'Lotificación Santa Emilia, lote 1 a 4, pasaje PGA, Caserío Totolco, Km 77, carretera a Chalatenango',
          Icon: 'PGR.jpg',
          mapURL: "https://maps.google.com/maps/place/14%C2%B002'27.4%22N+88%C2%B057'28.9%22W/@14.0409444,-88.9591221,18z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d14.0409563!4d-88.95804",
          coordinate: {
            latitude: 14.040956,
            longitude: -88.958040,
          },
          tiposAtencion: `Atención Legal`,
        },
        {
          id: 10,
          title: 'PGR COJUTEPEQUE',
          time: '8:00AM - 4:00PM LUN-VIER',
          phone: 23721366,
          direcction: '6a. C. Ote. Barrio San Juan, Calle a la Estación, No. 5, Cojutepeque',
          Icon: 'PGR.jpg',
          mapURL: "https://maps.google.com/maps/place/13%C2%B042'51.0%22N+88%C2%B055'37.0%22W/@13.7141667,-88.9274916,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.7141516!4d-88.9269301",
          coordinate: {
            latitude: 13.714152,
            longitude: -88.926930,
          },
          tiposAtencion: `Atención Legal`,
        },
        {
          id: 11,
          title: 'PGR SENSUNTEPEQUE',
          time: '8:00AM - 4:00PM LUN-VIER',
          phone: 23820133,
          direcction: 'Calle Doctor Jesús Velasco # 27, Barrio El Calvario, Sensuntepeque.',
          Icon: 'PGR.jpg',
          mapURL: "https://maps.google.com/maps/place/Alcald%C3%ADa+Municipal+de+Sensuntepeque/@13.876102,-88.6277311,19z/data=!4m5!3m4!1s0x8f64bd31d8c9b677:0x522504f59f8a9900!8m2!3d13.87587!4d-88.6274251",
          coordinate: {
            latitude: 13.875879,
            longitude: -88.627425,
          },
          tiposAtencion: `Atención Legal`,
        },
        {
          id: 12,
          title: 'PGR SAN VICENTE',
          time: '8:00AM - 4:00PM LUN-VIER',
          phone: 23932895,
          direcction: '7º Calle Oriente, No. 22, Barrio El Santuario San Vicente.',
          Icon: 'PGR.jpg',
          mapURL: "https://maps.google.com/maps/place/13%C2%B038'50.3%22N+88%C2%B046'57.9%22W/@13.6473056,-88.7832972,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.6472909!4d-88.7827535",
          coordinate: {
            latitude: 13.647291,
            longitude: -88.782753,
          },
          tiposAtencion: `Atención Legal`,
        },
        {
          id: 13,
          title: 'PGR LA UNION',
          time: '8:00AM - 4:00PM LUN-VIER',
          phone: 26042057,
          direcction: '5º Calle Poniente, Centro de Gobierno, Barrio el Centro, La Unión.',
          Icon: 'PGR.jpg',
          mapURL: "https://maps.google.com/maps/place/13%C2%B020'20.8%22N+87%C2%B050'35.0%22W/@13.3391111,-87.8436028,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.3391148!4d-87.8430462",
          coordinate: {
            latitude: 13.339115,
            longitude: -87.843046,
          },
          tiposAtencion: `Atención Legal`,
        },
        {
          id: 14,
          title: 'PGR SAN MIGUEL',
          time: '8:00AM - 4:00PM LUN-VIER',
          phone: 26616175,
          direcction: '3era. Av. Sur #606, Barrio San Nicolás, a Una Cuadra del Mercado San Nicolás, Frente a Hotel Caleta, San Miguel.',
          Icon: 'PGR.jpg',
          mapURL: "https://maps.google.com/maps/place/13%C2%B028'30.5%22N+88%C2%B010'39.0%22W/@13.4751389,-88.1780472,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.4751499!4d-88.1774993",
          coordinate: {
            latitude: 13.475150,
            longitude: -88.177499,
          },
          tiposAtencion: `Atención Legal`,
        },
        {
          id: 15,
          title: 'PGR SAN FCO. GOTERA',
          time: '8:00AM - 4:00PM LUN-VIER',
          phone: 26542073,
          direcction: 'Centro de Gobierno, 2do. Nivel, 2da. Calle Poniente y 3era. Av. Sur, Barrio El Centro, San Francisco Gotera.',
          Icon: 'PGR.jpg',
          mapURL: "https://maps.google.com/maps/place/13%C2%B041'44.8%22N+88%C2%B006'20.9%22W/@13.6957778,-88.1063528,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.6957715!4d-88.1057941",
          coordinate: {
            latitude: 13.695771,
            longitude: -88.105794,
          },
          tiposAtencion: `Atención Legal`,
        },
        {
          id: 16,
          title: 'PGR USULUTÁN',
          time: '8:00AM - 4:00PM LUN-VIER',
          phone: 26624759,
          direcction: '7º Avenida Norte y 4° Calle Poniente #15, Barrio La Merced, Usulután.',
          Icon: 'PGR.jpg',
          mapURL: "https://maps.google.com/maps/place/13%C2%B020'47.8%22N+88%C2%B026'34.3%22W/@13.3466111,-88.4434083,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.3466054!4d-88.4428672",
          coordinate: {
            latitude: 13.346605,
            longitude: -88.442867,
          },
          tiposAtencion: `Atención Legal`,
        },
        {
          id: 17,
          title: 'PGR ZACATECOLUCA',
          time: '8:00AM - 4:00PM LUN-VIER',
          phone: 23342856,
          direcction: 'Calle Doctor Miguel Tomás Molina, 2º Calle Poniente, # 28, Barrio Candelaria, Zacatecoluca.',
          Icon: 'PGR.jpg',
          mapURL: "https://maps.google.com/maps/place/13%C2%B030'28.4%22N+88%C2%B051'57.3%22W/@13.5078889,-88.8664639,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.5078986!4d-88.8659253",
          coordinate: {
            latitude: 13.507899,
            longitude: -88.865925,
          },
          tiposAtencion: `Atención Legal`,
        },
        {
          id: 18,
          title: 'PGR SANTA TECLA',
          time: '8:00AM - 4:00PM LUN-VIER',
          phone: 22886382,
          direcction: '6ª. Av. Sur y 11 C. Ote., Av. Dr. José Zablah Touché, No. 5-6, Santa Tecla.',
          Icon: 'PGR.jpg',
          mapURL: "https://maps.google.com/maps/place/13%C2%B040'17.5%22N+89%C2%B016'50.3%22W/@13.6715278,-89.2811861,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.6715323!4d-89.2806505",
          coordinate: {
            latitude: 13.671532,
            longitude: -89.280651,
          },
          tiposAtencion: `Atención Legal`,
        },
        {
          id: 19,
          title: 'PGR SAN SALVADOR',
          time: '8:00AM - 4:00PM LUN-VIER',
          phone: 22811021,
          direcction: '13 Avenida Norte y Novena Calle Poniente, Torre PGR, Centro de Gobierno, San Salvador',
          Icon: 'PGR.jpg',
          mapURL: "https://maps.google.com/maps/place/13%C2%B042'16.8%22N+89%C2%B011'50.4%22W/@13.7046667,-89.199522,17z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.7046705!4d-89.1973289",
          coordinate: {
            latitude: 13.704670,
            longitude: -89.197329,
          },
          tiposAtencion: `Atención Legal`,
        },
        {
          id: 20,
          title: 'PGR SOYAPANGO',
          time: '8:00AM - 4:00PM LUN-VIER',
          phone: 26042057,
          direcction: 'Av. Las Palmeras, Pasaje Morazán, # 3, Barrio El Progreso, Soyapango.',
          Icon: 'PGR.jpg',
          mapURL: "https://maps.google.com/maps/place/13%C2%B042'19.3%22N+89%C2%B009'17.0%22W/@13.7052069,-89.154734,20z/data=!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.7053527!4d-89.1547186",
          coordinate: {
            latitude: 13.705353,
            longitude: -89.154719,
          },
          tiposAtencion: `Atención Legal`,
        },
        {
          id: 21,
          title: 'Juzgado de Instrucción para una Vida Libre de Violencia y Discriminación contra las Mujeres, San Salvador',
          time: '8:00AM - 4:00PM LUN-VIER',
          phone: 22262086,
          direcction: 'Colonia Médica Avenida Dr. Dimas Funes Hartmann # 20 San Salvador.',
          Icon: 'CSJ.jpg',
          mapURL: "https://maps.google.com/maps/place/13%C2%B042'30.1%22N+89%C2%B011'56.7%22W/@13.7083611,-89.2001776,18z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.7083712!4d-89.1990721",
          coordinate: {
            latitude: 13.708371,
            longitude: -89.199072,
          },
          tiposAtencion: `Atención Legal`,
        },
        {
          id: 22,
          title: 'Juzgado de Instrucción para una Vida Libre de Violencia y Discriminación contra las Mujeres, Santa Ana',
          time: '8:00AM - 4:00PM LUN-VIER',
          phone: 24408353,
          direcction: '12 Avenida Sur Entre 27 y 29 calle poniente # 98, Santa Ana',
          Icon: 'CSJ.jpg',
          mapURL: "https://maps.google.com/maps/place/13%C2%B058'57.9%22N+89%C2%B033'53.9%22W/@13.98275,-89.5671609,17z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.9827584!4d-89.5649762",
          coordinate: {
            latitude: 13.982758,
            longitude: -89.564976,
          },
          tiposAtencion: `Atención Legal`,
        },
        {
          id: 23,
          title: 'Juzgado de Instrucción para una Vida Libre de Violencia y Discriminación Contra Las Mujeres, San Miguel',
          time: '8:00AM - 4:00PM LUN-VIER',
          phone: 26610822,
          direcction: '8° calle poniente #309 Barrio San Felipe, San Miguel.',
          Icon: 'CSJ.jpg',
          mapURL: "https://maps.google.com/maps/place/Juzgados+Especializados+de+la+Ni%C3%B1ez+y+Adolescencia/@13.4871757,-88.1902751,17z/data=!4m5!3m4!1s0x0:0x822bfc518cc46182!8m2!3d13.4875397!4d-88.1890362",
          coordinate: {
            latitude: 13.487669,
            longitude: -88.189038,
          },
          tiposAtencion: `Atención Legal`,
        },
        {
          id: 24,
          title: 'Juzgado de Instrucción para una Vida Libre de Violencia y Discriminación Contra Las Mujeres, Cojutepeque',
          time: '8:00AM - 4:00PM LUN-VIER',
          phone: 26610822,
          direcction: 'Avenida José María Rivas, número #6, barrio el centro. Cojutepeque, Cuscatlán.',
          Icon: 'CSJ.jpg',
          mapURL: "https://maps.app.goo.gl/LnBcvRyy3PQqH5PZA",
          coordinate: {
            latitude: 13.723146,
            longitude: -88.934467,
          },
          tiposAtencion: `Atención Legal`,
        },
      ],
    },
    {
      titulo: "ATENCIÓN EMOCIONAL",
      logo: "/assets/images/servicios/atencionEmocional.png",
      items: [
        {
          id: 1,
          title: 'UIAEM CIUDAD BARRIOS',
          time: '24/7',
          phone: 26841500,
          direcction: '6ª. Av. Norte, Bo. Roma, Hospital Nacional General "Monseñor Oscar Arnulfo Romero y Galdámez", Ciudad Barios',
          Icon: 'MINSAL.jpg',
          mapURL: "https://maps.google.com/maps/place/Hospital+Nacional+De+Ciudad+Barrios/@13.766542,-88.2683314,19z/data=!4m5!3m4!1s0x8f64da4b033c8b91:0x228aabf686a621a8!8m2!3d13.7669665!4d-88.2682157",
          coordinate: {
            latitude: 13.767014,
            longitude: -88.102023,
          },
          tiposAtencion: `Salud`,
        },

      ],
    },
    {
      titulo: "ATENCIÓN POLICIAL",
      logo: "/assets/images/servicios/atencionPolicial.png",
      items: [

      ],
    },
    {
      titulo: "GRUPOS DE APOYO",
      logo: "/assets/images/servicios/gruposdeApoyo.png",
      items: [

      ],
    },
    // Agrega los demás servicios aquí...
  ],
  leyes_y_decretos: [
    {
      titulo: "CÓDIGO PENAL",
      url_audio: "",
      url_pdf: "/pdf/CODIGO PENAL 26-04-1998.pdf",
      url_codigo_componente: "LeyOne",
    },
    {
      titulo: "CÓDIGO PROCESAL PENAL",
      url_audio: "",
      url_pdf: "/pdf/CODIGO PROCESAL PENAL 2011.pdf",
      url_codigo_componente: "LeyOne",
    },
    {
      titulo: "CONSTITUCIÓN DE LA REPÚBLICA DE EL SALVADOR",
      url_audio: "",
      url_pdf: "/pdf/CONSTITUCIÓN 1983.pdf",
      url_codigo_componente: "LeyOne",
    },
    {
      titulo: "CONVENCION AMERICANA DE DERECHOS HUMANOS PACTO DE SAN JOSE",
      url_audio: "",
      url_pdf: "/pdf/CONVENCION AMERICANA DE DERECHOS HUMANOS PACTO DE SAN JOSE 22-11-1969.pdf",
      url_codigo_componente: "LeyOne",
    },
    {
      titulo: "CONVENCION BELEM DO PARA 1994",
      url_audio: "",
      url_pdf: "/pdf/CONVENCION BELEM DO PARA 1994.pdf",
      url_codigo_componente: "LeyOne",
    },
    {
      titulo: "CONVENCION CEDAW 1979",
      url_audio: "",
      url_pdf: "/pdf/CONVENCION CEDAW 1979.pdf",
      url_codigo_componente: "LeyOne",
    },
    {
      titulo: "CONVENCION DE LOS DERECHOS DEL NIÑO 1989",
      url_audio: "",
      url_pdf: "/pdf/CONVENCION DE LOS DERECHOS DEL NIÑO 1989.pdf",
      url_codigo_componente: "LeyOne",
    },
    {
      titulo: "CONVENIO 190 OIT SOBRE LA VIOLENCIA Y EL ACOSO 2019",
      url_audio: "",
      url_pdf: "/pdf/Convenio 190 OIT sobre la violencia y el acoso 21-06-2019.pdf",
      url_codigo_componente: "LeyOne",
    },
    {
      titulo: "CÓDIGO DE FAMILIA",
      url_audio: "",
      url_pdf: "/pdf/DL CODIGO DE FAMILIA 11-10-93.pdf",
      url_codigo_componente: "LeyOne",
    },

    {
      titulo: "LEY CONTRA LA VIOLENCIA INTRAFAMILIAR",
      url_audio: "",
      url_pdf: "/pdf/DL LEY CONTRA LA VIOLENCIA INTRAFAMILIAR 28-11-96.pdf",
      url_codigo_componente: "LeyOne",
    },
    {
      titulo: "LEY CRECER JUNTOS PARA PROTECCION INTEGRAL DE LA PRIMERA INFANCIA NIÑEZ Y ADOLESCENCIA",
      url_audio: "",
      url_pdf: "/pdf/DL LEY CRECER JUNTOS PARA PROTECCION INTEGRAL DE LA PRIMERA INFANCIA NIÑEZ Y ADOLESCENCIA 22-06-2022.pdf",
      url_codigo_componente: "LeyOne",
    },
    {
      titulo: "LEY DE IGUALDAD EQUIDAD Y ERRADICACIÓN DE LA DISCRIMINACIÓN CONTRA LAS MUJERES",
      url_audio: "",
      url_pdf: "/pdf/DL LEY DE IGUALDAD EQUIDAD Y ERRADICACIÓN DE LA DISCRIMINACIÓN CONTRA LAS MUJERES 08-04-2011 CON REFORMAS.pdf",
      url_codigo_componente: "LeyOne",
    },
    {
      titulo: "LEY ESPECIAL INTEGRAL PARA UNA VIDA LIBRE DE VIOLENCIA PARA LAS MUJERES",
      url_audio: "",
      url_pdf: "/pdf/DL LEY ESPECIAL INTEGRAL PARA UNA VIDA LIBRE DE VIOLENCIA PARA LAS MUJERES 25-07-2022 CON TODAS REFORMAS.pdf",
      url_codigo_componente: "LeyOne",
    },
    {
      titulo: "LEY GENERAL DE PREVENCION DE RIESGOS EN LOS LUGARES DE TRABAJO",
      url_audio: "",
      url_pdf: "/pdf/DL LEY GENERAL DE PREVENCION DE RIESGOS EN LOS LUGARES DE TRABAJO 10-04-10.pdf",
      url_codigo_componente: "LeyOne",
    },
  ],
  oficinas_mapa: [
    {
      id: 1,
      title: "UIAEM CIUDAD BARRIOS",
      time: "24/7",
      phone: 26841500,
      direcction:
        '6ª. Av. Norte, Bo. Roma, Hospital Nacional General "Monseñor Oscar Arnulfo Romero y Galdámez", Ciudad Barios',
      Icon: "MINSAL.jpg",
      mapURL:
        "https://maps.google.com/maps/place/Hospital+Nacional+De+Ciudad+Barrios/@13.766542,-88.2683314,19z/data=!4m5!3m4!1s0x8f64da4b033c8b91:0x228aabf686a621a8!8m2!3d13.7669665!4d-88.2682157",
      coordinate: {
        latitude: 13.767014,
        longitude: -88.102023,
      },
      tiposAtencion: `Salud`,
    },
    {
      id: 2,
      title: "UIAEM ZACATECOLUCA",
      time: "24/7",
      phone: 23340190,
      direcction:
        "Final Av. Juan Manuel Rodríguez, Calle al Volcán, Hospital Nacional “Santa Teresa”, Zacatecoluca.",
      Icon: "MINSAL.jpg",
      mapURL:
        "https://maps.google.com/maps/place/Hospital+Nacional+Santa+Teresa/@13.5176091,-88.8709862,17z/data=!4m5!3m4!1s0x8f7cac9f5e9e7e51:0xe1d961b716d36c86!8m2!3d13.5176039!4d-88.8687975",
      coordinate: {
        latitude: 13.517823,
        longitude: -88.86839,
      },
      tiposAtencion: `Salud`,
    },
    {
      id: 3,
      title: "UIAEM HOSPITAL DE LA MUJER",
      time: "24/7",
      phone: 25239500,
      direcction:
        "Final 25 Avenida Sur y Calle Francisco Menéndez, Barrio Santa Anita, Hospital Nacional de la Mujer, San Salvador.",
      Icon: "MINSAL.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B040'15.7%22N+89%C2%B016'41.5%22W/@13.6710278,-89.2787416,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.671029!4d-89.2781932",
      coordinate: {
        latitude: 13.671029,
        longitude: -89.278193,
      },
      tiposAtencion: `Salud`,
    },

    {
      id: 4,
      title: "UIAEM HOSPITAL SAN JUAN DE DIOS, SAN MIGUEL",
      time: "24/7",
      phone: 26843000,
      direcction:
        "Final 11 C. Pte.. Y 23 Av. Sur Colonia Ciudad Jardín, Hospital Nacional Regional “San Juan de Dios” San Miguel ",
      Icon: "MINSAL.jpg",
      mapURL: "https://goo.gl/maps/jLBi6oi8sENJ7QKG6",
      coordinate: {
        latitude: 13.474493,
        longitude: -88.192952,
      },
      tiposAtencion: `Salud`,
    },
    {
      id: 5,
      title: 'UIAEM HOSPITAL "DR JORGE MAZZINI", SONSONATE',
      time: "24/7",
      phone: 24510200,
      direcction:
        'Calle Alberto Masferrer Pte #3-1. Hospital Nacional General "Dr. Jorge Mazzini Villacorta" Sonsonate ',
      Icon: "MINSAL.jpg",
      mapURL: "https://goo.gl/maps/p8sc8YxHey3ZUYVp9",
      coordinate: {
        latitude: 13.722899,
        longitude: -89.729483,
      },
      tiposAtencion: `Salud`,
    },
    {
      id: 6,
      title: "UIAEM HOSPITAL NACIONAL SAN JUAN DE DIOS, SANTA ANA",
      time: "24/7",
      phone: 24359500,
      direcction:
        "Final 13 Av. Sur No.1, Santa Ana. Hospital Nacional Regional “San Juan de Dios” de Santa Ana. ",
      Icon: "MINSAL.jpg",
      mapURL: "https://goo.gl/maps/Jzv57uSvbgJ9NkD26",
      coordinate: {
        latitude: 13.99219,
        longitude: -89.551194,
      },
      tiposAtencion: `Salud`,
    },
    {
      id: 7,
      title: "UIAEM HOSPITAL DE CHALCHUAPA, CHALCHUAPA",
      time: "24/7",
      phone: 24440217,
      direcction:
        "Final Av. 2 de abril Nte. Hospital Nacional General Chalchuapa",
      Icon: "MINSAL.jpg",
      mapURL: "https://goo.gl/maps/u9u8gWFm5D6UGfrW6",
      coordinate: {
        latitude: 13.989313,
        longitude: -89.678495,
      },
      tiposAtencion: `Salud`,
    },
    {
      id: 8,
      title: "UIAEM HOSPITAL NACIONAL, COJUTEPEQUE",
      time: "24/7",
      phone: 29912200,
      direcction:
        "Kilómetro 33 Carretera Panamerica Barrio El Calvario, Hospital Nacional “Nuestra Señora de Fátima”. Cojutepeque ",
      Icon: "MINSAL.jpg",
      mapURL: "https://goo.gl/maps/D96GPCvP8nBNw1vT7",
      coordinate: {
        latitude: 13.726128,
        longitude: -88.942147,
      },
      tiposAtencion: `Salud`,
    },
    {
      id: 9,
      title: "UIAEM HOSPITAL NACIONAL, SAN VICENTE",
      time: "24/7",
      phone: 23930267,
      direcction:
        '2ª. Av. Sur No. 23, Hospital Nacional General  "Santa Gertrudis", San Vicente',
      Icon: "MINSAL.jpg",
      mapURL: "https://goo.gl/maps/bsQGnEnkoay6ojph9",
      coordinate: {
        latitude: 13.641217,
        longitude: -88.783838,
      },
      tiposAtencion: `Salud`,
    },
    {
      id: 10,
      title: "UIAEM HOSPITAL NACIONAL SIQUIATRICO, SOYAPANGO",
      time: "24/7",
      phone: 23270200,
      direcction:
        "Calle La Fuente, Ctón Venecia, Hospital Nacional General  y de Psiquiatría “Dr. José Molina Martínez”, Soyapango",
      Icon: "MINSAL.jpg",
      mapURL: "https://goo.gl/maps/3N5JxAzgycTg5pat5",
      coordinate: {
        latitude: 13.714787,
        longitude: -89.139706,
      },
      tiposAtencion: `Salud`,
    },
    {
      id: 11,
      title: "UIAEM HOSPITAL ZACAMIL ",
      time: "24/7",
      phone: 25945000,
      direcction:
        "Urb. José Simeón Cañas, Col. Zacamil, Hospital Nacional “ Dr. Juan Jose Fernández” , Mejicanos",
      Icon: "MINSAL.jpg",
      mapURL: "https://goo.gl/maps/j5DeNfAPbVkjkEBG9",
      coordinate: {
        latitude: 13.729168,
        longitude: -89.20723,
      },
      tiposAtencion: `Salud`,
    },
    {
      id: 12,
      title: "UIAEM HOSPITAL SAN BARTOLO, ILPANGO",
      time: "24/7",
      phone: 22013100,
      direcction:
        'Final C. Francisco Menéndez, contiguo Zona Franca San Bartolo, Hospital Nacional General "Enf. Angélica Vidal de Najarro", Ilopango',
      Icon: "MINSAL.jpg",
      mapURL: "https://goo.gl/maps/YonvxkTkr1vqtx9c8",
      coordinate: {
        latitude: 13.705145,
        longitude: -89.105579,
      },
      tiposAtencion: `Salud`,
    },
    {
      id: 13,
      title: "UIAEM HOSPITAL LA UNION",
      time: "24/7",
      phone: 26044104,
      direcction:
        "1ª. C. Ote y 9ª Av. Nte. No. 8, Barrio Concepción, Hospital Nacional General La Unión ",
      Icon: "MINSAL.jpg",
      mapURL: "https://goo.gl/maps/28uh1ZQjx9TDCtGs9",
      coordinate: {
        latitude: 13.347503,
        longitude: -87.875909,
      },
      tiposAtencion: `Salud`,
    },
    {
      id: 14,
      title: "UIAEM HOSPITAL SAN FRANCISCO GOTERA",
      time: "24/7",
      phone: 26457100,
      direcction:
        'Final Av. Thomson Norte, Barrio La Cruz, Hospital Nacional General  "Dr. Héctor Antonio Hernández Flores", San Francisco Gotera ',
      Icon: "MINSAL.jpg",
      mapURL: "https://goo.gl/maps/MZpSTs8aDwxg4F3m7",
      coordinate: {
        latitude: 13.698033,
        longitude: -88.102142,
      },
      tiposAtencion: `Salud`,
    },
    {
      id: 15,
      title: "UIAEM HOSPITAL NACIONAL, USULUTAN",
      time: "24/7",
      phone: 26338800,
      direcction:
        "Final Calle Federico Penado, salida a San Salvador, Hospital Nacional San Pedro de Usulutan.",
      Icon: "MINSAL.jpg",
      mapURL: "https://goo.gl/maps/a1vX5GsxVEtKNJEk7",
      coordinate: {
        latitude: 13.34361,
        longitude: -88.449821,
      },
      tiposAtencion: `Salud`,
    },
    {
      id: 16,
      title: "UIAEM HOSPITAL NACIONAL, JIQUILISCO",
      time: "24/7",
      phone: 26338800,
      direcction:
        "1ª. Av. Sur, Calle a Puerto Avalos, Cantón Roquinete, Hospital Nacional de Jiquilisco ",
      Icon: "MINSAL.jpg",
      mapURL: "https://goo.gl/maps/18pNJU6VjJqwxbgw7",
      coordinate: {
        latitude: 13.319563,
        longitude: -88.57193,
      },
      tiposAtencion: `Salud`,
    },
    {
      id: 17,
      title: "UIAEM HOSPITAL SAN RAFAEL, SANTA TECLA",
      time: "24/7",
      phone: 25239500,
      direcction:
        "4ª. C Ote No. 9-2. Santa Tecla,  Hospital Nacional General “San Rafael”, Santa Tecla ",
      Icon: "MINSAL.jpg",
      mapURL: "https://goo.gl/maps/UCDNe8VMhBJXLZP19",
      coordinate: {
        latitude: 13.671197,
        longitude: -89.278237,
      },
      tiposAtencion: `Salud`,
    },
    {
      id: 18,
      title:
        "Unidad de Atención Preconcepcional en la Unidad Comunitaria de Salud Familiar Especializada en el municipio de Olocuilta. ",
      time: "7:30AM - 3:30PM LUN-VIER",
      phone: 23306006,
      direcction:
        "Barrio El Calvario, Av. Dr. Demetrio Bernal, Municipio de OLOCUILTA",
      Icon: "MINSAL.jpg",
      mapURL:
        "https://maps.google.com/maps/place/Alcald%C3%ADa+Municipal+de+Olocuilta/@13.5691909,-89.1181725,19z/data=!4m5!3m4!1s0x0:0x6a74a0e6c409162!8m2!3d13.5695849!4d-89.118253",
      coordinate: {
        latitude: 13.569654,
        longitude: -89.118248,
      },
      tiposAtencion: `Salud`,
    },
    {
      id: 19,
      title: "FGR SONSONATE",
      time: "24/7",
      phone: 28913107,
      direcction:
        "Hacienda Las Delicias, Edificio Los Portales, Km. 67, Carretera hacia Acajutla, a 500 metros del Colegio San Francisco, Sonsonate",
      Icon: "FGR.jpg",
      mapURL:
        "https://maps.google.com/maps/place/Fiscalia+General+De+La+Republica/@13.7037589,-89.7345068,683m/data=!3m2!1e3!4b1!4m5!3m4!1s0x8f62b7a224adba23:0x1918997d24eb0e6!8m2!3d13.7037589!4d-89.7327266",
      coordinate: {
        latitude: 13.703813,
        longitude: -89.7326416,
      },
      tiposAtencion: `Atención Legal`,
    },
    {
      id: 20,
      title: "FGR SANTA ANA",
      time: "24/7",
      phone: 28913004,
      direcction:
        "8ª Calle Poniente y Avenida José Matías Delgado Norte. Santa Ana.",
      Icon: "FGR.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B043'41.5%22N+89%C2%B042'48.7%22W/@13.7281944,-89.7157165,17z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.7281988!4d-89.713526",
      coordinate: {
        latitude: 13.728199,
        longitude: -89.713526,
      },
      tiposAtencion: `Atención Legal`,
    },
    {
      id: 21,
      title: "FGR SAN MIGUEL",
      time: "24/7",
      phone: 27912052,
      direcction:
        "Barrio Concepción, Entre 15 y 17 Calle Oriente No 804, (Costado sur Centro de Gobierno). San Miguel.",
      Icon: "FGR.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B028'24.3%22N+88%C2%B010'16.0%22W/@13.4734167,-88.1716583,171m/data=!3m2!1e3!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.4734038!4d-88.1711137",
      coordinate: {
        latitude: 13.473404,
        longitude: -88.171114,
      },
      tiposAtencion: `Atención Legal`,
    },
    {
      id: 22,
      title: "FGR ZARAGOZA LA LIBERTAD",
      time: "24/7",
      phone: 29908000,
      direcction:
        "Centro Comercial Plaza Zaragoza, 3ª Calle Oriente, Entre Av. Monseñor Oscar Arnulfo Romero y Primera Ave. España, Zaragoza, La Libertad",
      Icon: "FGR.jpg",
      mapURL:
        "https://maps.google.com/maps/place/Fiscal%C3%ADa+General+de+la+Rep%C3%BAblica;+Oficina+La+Libertad/@13.5908791,-89.2886142,85m/data=!3m1!1e3!4m5!3m4!1s0x8f632c55c6950b4f:0x3cd82c5bd5c6255c!8m2!3d13.5908686!4d-89.2885172",
      coordinate: {
        latitude: 13.590962,
        longitude: -89.288509,
      },
      tiposAtencion: `Atención Legal`,
    },
    {
      id: 23,
      title: "FGR ANTIGUO CUSCATLAN",
      time: "24/7",
      phone: 25937210,
      direcction:
        "Colonia y Av La Sultana, Edificio film No. G-12, Antiguo Cuscatlán. La Libertad. Por la UCA y el Super Selectos",
      Icon: "FGR.jpg",
      mapURL:
        "https://maps.google.com/maps/place/Fiscal%C3%ADa+General+de+la+Rep%C3%BAblica/@13.680435,-89.2404176,341m/data=!3m1!1e3!4m5!3m4!1s0x8f6331d325656785:0x47df8a1057ea7b06!8m2!3d13.680573!4d-89.2399597",
      coordinate: {
        latitude: 13.680671,
        longitude: -89.239879,
      },
      tiposAtencion: `Atención Legal`,
    },
    {
      id: 24,
      title: "PGR AHUACHAPAN",
      time: "7:30AM - 3:30PM LUN-VIER",
      phone: 24130838,
      direcction: "3a. C. Ote., No. 2-2, Barrio San José, Ahuachapán.",
      Icon: "PGR.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B055'06.0%22N+89%C2%B050'45.5%22W/@13.9183333,-89.8465194,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.9183436!4d-89.8459832",
      coordinate: {
        latitude: 13.918344,
        longitude: -89.845983,
      },
      tiposAtencion: `Atención Legal`,
    },
    {
      id: 25,
      title: "PGR ZONSACATE",
      time: "8:00AM - 4:00PM LUN-VIER",
      phone: 24503354,
      direcction:
        "Colonia San Francisco, 4ta. calle oriente, casa 22, Dos Cuadras Al Poniente de FGR de Sonzacate. Sonsonate",
      Icon: "PGR.jpg",
      mapURL:
        "https://maps.google.com/maps/place/Procuraduria+General+De+La+Republica+Sonsonate/@13.7279383,-89.7157469,17z/data=!4m5!3m4!1s0x8f62b8247fed7b69:0xf1f3ec403f8ac60e!8m2!3d13.7279383!4d-89.7135582",
      coordinate: {
        latitude: 13.727988,
        longitude: -89.713561,
      },
      tiposAtencion: `Atención Legal`,
    },

    {
      id: 26,
      title: "PGR SANTA ANA",
      time: "8:00AM - 4:00PM LUN-VIER",
      phone: 24479487,
      direcction:
        "Av. Fray Felipe de Jesús Moraga Norte, Entre Calle Libertad Poniente y 4º Calle Poniente #2, Santa Ana.",
      Icon: "PGR.jpg",
      mapURL:
        "https://maps.google.com/maps/place/Procuraduria+General+de+la+Republica/@13.9962792,-89.5638329,19z/data=!3m1!4b1!4m5!3m4!1s0x8f62e8b0ba1f24bb:0x9fe92800be9bf15f!8m2!3d13.9962792!4d-89.5632857",
      coordinate: {
        latitude: 13.996344,
        longitude: -89.563203,
      },
      tiposAtencion: `Atención Legal`,
    },
    {
      id: 27,
      title: "PGR CHALATENANGO",
      time: "8:00AM - 4:00PM LUN-VIER",
      phone: 23352527,
      direcction:
        "Lotificación Santa Emilia, lote 1 a 4, pasaje PGA, Caserío Totolco, Km 77, carretera a Chalatenango",
      Icon: "PGR.jpg",
      mapURL:
        "https://maps.google.com/maps/place/14%C2%B002'27.4%22N+88%C2%B057'28.9%22W/@14.0409444,-88.9591221,18z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d14.0409563!4d-88.95804",
      coordinate: {
        latitude: 14.040956,
        longitude: -88.95804,
      },
      tiposAtencion: `Atención Legal`,
    },
    {
      id: 28,
      title: "PGR COJUTEPEQUE",
      time: "8:00AM - 4:00PM LUN-VIER",
      phone: 23721366,
      direcction:
        "6a. C. Ote. Barrio San Juan, Calle a la Estación, No. 5, Cojutepeque",
      Icon: "PGR.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B042'51.0%22N+88%C2%B055'37.0%22W/@13.7141667,-88.9274916,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.7141516!4d-88.9269301",
      coordinate: {
        latitude: 13.714152,
        longitude: -88.92693,
      },
      tiposAtencion: `Atención Legal`,
    },
    {
      id: 29,
      title: "PGR SENSUNTEPEQUE",
      time: "8:00AM - 4:00PM LUN-VIER",
      phone: 23820133,
      direcction:
        "Calle Doctor Jesús Velasco # 27, Barrio El Calvario, Sensuntepeque.",
      Icon: "PGR.jpg",
      mapURL:
        "https://maps.google.com/maps/place/Alcald%C3%ADa+Municipal+de+Sensuntepeque/@13.876102,-88.6277311,19z/data=!4m5!3m4!1s0x8f64bd31d8c9b677:0x522504f59f8a9900!8m2!3d13.87587!4d-88.6274251",
      coordinate: {
        latitude: 13.875879,
        longitude: -88.627425,
      },
      tiposAtencion: `Atención Legal`,
    },
    {
      id: 30,
      title: "PGR SAN VICENTE",
      time: "8:00AM - 4:00PM LUN-VIER",
      phone: 23932895,
      direcction: "7º Calle Oriente, No. 22, Barrio El Santuario San Vicente.",
      Icon: "PGR.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B038'50.3%22N+88%C2%B046'57.9%22W/@13.6473056,-88.7832972,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.6472909!4d-88.7827535",
      coordinate: {
        latitude: 13.647291,
        longitude: -88.782753,
      },
      tiposAtencion: `Atención Legal`,
    },
    {
      id: 31,
      title: "PGR LA UNION",
      time: "8:00AM - 4:00PM LUN-VIER",
      phone: 26042057,
      direcction:
        "5º Calle Poniente, Centro de Gobierno, Barrio el Centro, La Unión.",
      Icon: "PGR.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B020'20.8%22N+87%C2%B050'35.0%22W/@13.3391111,-87.8436028,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.3391148!4d-87.8430462",
      coordinate: {
        latitude: 13.339115,
        longitude: -87.843046,
      },
      tiposAtencion: `Atención Legal`,
    },
    {
      id: 32,
      title: "PGR SAN MIGUEL",
      time: "8:00AM - 4:00PM LUN-VIER",
      phone: 26616175,
      direcction:
        "3era. Av. Sur #606, Barrio San Nicolás, a Una Cuadra del Mercado San Nicolás, Frente a Hotel Caleta, San Miguel.",
      Icon: "PGR.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B028'30.5%22N+88%C2%B010'39.0%22W/@13.4751389,-88.1780472,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.4751499!4d-88.1774993",
      coordinate: {
        latitude: 13.47515,
        longitude: -88.177499,
      },
      tiposAtencion: `Atención Legal`,
    },
    {
      id: 33,
      title: "PGR SAN FCO GOTERA",
      time: "8:00AM - 4:00PM LUN-VIER",
      phone: 26542073,
      direcction:
        "Centro de Gobierno, 2do. Nivel, 2da. Calle Poniente y 3era. Av. Sur, Barrio El Centro, San Francisco Gotera.",
      Icon: "PGR.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B041'44.8%22N+88%C2%B006'20.9%22W/@13.6957778,-88.1063528,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.6957715!4d-88.1057941",
      coordinate: {
        latitude: 13.695771,
        longitude: -88.105794,
      },
      tiposAtencion: `Atención Legal`,
    },
    {
      id: 34,
      title: "PGR USULUTAN",
      time: "8:00AM - 4:00PM LUN-VIER",
      phone: 26624759,
      direcction:
        "7º Avenida Norte y 4° Calle Poniente #15, Barrio La Merced, Usulután.",
      Icon: "PGR.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B020'47.8%22N+88%C2%B026'34.3%22W/@13.3466111,-88.4434083,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.3466054!4d-88.4428672",
      coordinate: {
        latitude: 13.346605,
        longitude: -88.442867,
      },
      tiposAtencion: `Atención Legal`,
    },
    {
      id: 35,
      title: "PGR ZACATECOLUCA",
      time: "8:00AM - 4:00PM LUN-VIER",
      phone: 23342856,
      direcction:
        "Calle Doctor Miguel Tomás Molina, 2º Calle Poniente, # 28, Barrio Candelaria, Zacatecoluca.",
      Icon: "PGR.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B030'28.4%22N+88%C2%B051'57.3%22W/@13.5078889,-88.8664639,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.5078986!4d-88.8659253",
      coordinate: {
        latitude: 13.507899,
        longitude: -88.865925,
      },
      tiposAtencion: `Atención Legal`,
    },
    {
      id: 36,
      title: "PGR SANTA TECLA",
      time: "8:00AM - 4:00PM LUN-VIER",
      phone: 22886382,
      direcction:
        "6ª. Av. Sur y 11 C. Ote., Av. Dr. José Zablah Touché, No. 5-6, Santa Tecla.",
      Icon: "PGR.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B040'17.5%22N+89%C2%B016'50.3%22W/@13.6715278,-89.2811861,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.6715323!4d-89.2806505",
      coordinate: {
        latitude: 13.671532,
        longitude: -89.280651,
      },
      tiposAtencion: `Atención Legal`,
    },
    {
      id: 37,
      title: "PGR SAN SALVADOR",
      time: "8:00AM - 4:00PM LUN-VIER",
      phone: 22811021,
      direcction:
        "13 Avenida Norte y Novena Calle Poniente, Torre PGR, Centro de Gobierno, San Salvador",
      Icon: "PGR.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B042'16.8%22N+89%C2%B011'50.4%22W/@13.7046667,-89.199522,17z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.7046705!4d-89.1973289",
      coordinate: {
        latitude: 13.70467,
        longitude: -89.197329,
      },
      tiposAtencion: `Atención Legal`,
    },
    {
      id: 38,
      title: "PGR SOYAPANGO",
      time: "8:00AM - 4:00PM LUN-VIER",
      phone: 26042057,
      direcction:
        "Av. Las Palmeras, Pasaje Morazán, # 3, Barrio El Progreso, Soyapango.",
      Icon: "PGR.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B042'19.3%22N+89%C2%B009'17.0%22W/@13.7052069,-89.154734,20z/data=!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.7053527!4d-89.1547186",
      coordinate: {
        latitude: 13.705353,
        longitude: -89.154719,
      },
      tiposAtencion: `Atención Legal`,
    },
    {
      id: 39,
      title: "UNIMUJER-ODAC AHUACHAPAN",
      time: "24/7",
      phone: 24208406,
      direcction:
        "Av. Morazán Frente a Colonia IVU a 50 Metros de Los Bomberos Ahuachapán.",
      Icon: "PNC.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B055'39.3%22N+89%C2%B051'05.8%22W/@13.9275833,-89.8521583,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.927571!4d-89.8516172",
      coordinate: {
        latitude: 13.927571,
        longitude: -89.851617,
      },
      tiposAtencion: `Atención Policial, Auxilio en Crisis, Alojamiento Temporal`,
    },
    {
      id: 40,
      title: "UNIMUJER-ODAC CARA SUCIA",
      time: "24/7",
      phone: 24370836,
      direcction:
        "6a. Calle Ote Bo. San Martín, Cton. Cara Sucia San Francisco Menéndez, Ahuachapán",
      Icon: "PNC.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B046'44.0%22N+90%C2%B001'58.3%22W/@13.7788889,-90.0334083,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.7788833!4d-90.0328613",
      coordinate: {
        latitude: 13.778883,
        longitude: -90.032861,
      },
      tiposAtencion: `Atención Policial, Auxilio en Crisis, Alojamiento Temporal, Ludoteca`,
    },
    {
      id: 41,
      title: "UNIMUJER-ODAC IZALCO",
      time: "24/7",
      phone: 24535364,
      direcction:
        "1° Calle Oriente Entre Avenida Morazán y 2° Avenida Sur, Izalco, departamento de Sonsonate.",
      Icon: "PNC.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B044'49.6%22N+89%C2%B040'27.3%22W/@13.7471111,-89.6747972,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.7470979!4d-89.6742383",
      coordinate: {
        latitude: 13.747098,
        longitude: -89.674238,
      },
      tiposAtencion: `Atención Policial, Auxilio en Crisis, Alojamiento Temporal, Ludoteca`,
    },
    {
      id: 42,
      title: "UNIMUJER-ODAC SANTA ANA",
      time: "24/7",
      phone: 24208666,
      direcction:
        "25 Calle Pte. Entre 14 y 16 Av. Sur, Edificio Mario Calvo, Santa Ana",
      Icon: "PNC.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B059'03.1%22N+89%C2%B033'55.9%22W/@13.9841944,-89.566075,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.9841985!4d-89.565534",
      coordinate: {
        latitude: 13.984199,
        longitude: -89.565534,
      },
      tiposAtencion: `Atención Policial, Auxilio en Crisis, Alojamiento Temporal, Ludoteca`,
    },
    {
      id: 43,
      title: "UNIMUJER-ODAC METAPAN",
      time: "24/7",
      phone: 24022857,
      direcction:
        "Carretera que de Santa Ana Conduce a Metapán Km 110, Frente Hospital Nacional Arturo Morales, Metapán, Santa Ana. ",
      Icon: "PNC.jpg",
      mapURL:
        "https://maps.google.com/maps/place/14%C2%B019'30.3%22N+89%C2%B026'37.6%22W/@14.3250833,-89.444325,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d14.3250703!4d-89.4437866",
      coordinate: {
        latitude: 14.32507,
        longitude: -89.443787,
      },
      tiposAtencion: `Atención Policial, Auxilio en Crisis, Alojamiento Temporal, Ludoteca`,
    },
    {
      id: 44,
      title: "UNIMUJER-ODAC CHALCHUAPA",
      time: "24/7",
      phone: 24085065,
      direcction:
        "11 Avenida Sur y 1 Calle Ote, Local 68 Chalchuapa, Santa Ana.",
      Icon: "PNC.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B058'53.0%22N+89%C2%B041'04.0%22W/@13.9813889,-89.6866331,17z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.9813837!4d-89.6844539",
      coordinate: {
        latitude: 13.981384,
        longitude: -89.684454,
      },
      tiposAtencion: `Atención Policial, Auxilio en Crisis, Alojamiento Temporal, Ludoteca`,
    },
    {
      id: 45,
      title: "UNIMUJER-ODAC CHALATENANGO",
      time: "24/7",
      phone: 23012352,
      direcction:
        "Final Calle San Martín, No.19 Costado Sur de Alcaldía municipal de Chalatenango, Frente a oficinas de Claro ",
      Icon: "PNC.jpg",
      mapURL:
        "https://maps.google.com/maps/place/14%C2%B002'33.2%22N+88%C2%B056'07.1%22W/@14.0425556,-88.9363999,18z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d14.0425509!4d-88.9353104",
      coordinate: {
        latitude: 14.042551,
        longitude: -88.93531,
      },
      tiposAtencion: `Atención Policial, Auxilio en Crisis, Alojamiento Temporal, Ludoteca`,
    },
    {
      id: 46,
      title: "UNIMUJER-ODAC NUEVA CONCEPCION, CHALATENANGO",
      time: "24/7",
      phone: 23357031,
      direcction:
        "4° Calle Poniente y 5° Av. Sur, Barrio El Carmen, Nueva Concepción, Chalatenango.",
      Icon: "PNC.jpg",
      mapURL:
        "https://maps.google.com/maps/place/14%C2%B007'37.5%22N+89%C2%B017'30.1%22W/@14.1270833,-89.2927887,18z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d14.1270872!4d-89.2917065",
      coordinate: {
        latitude: 14.127087,
        longitude: -89.291706,
      },
      tiposAtencion: `Atención Policial, Auxilio en Crisis, Alojamiento Temporal, Ludoteca`,
    },
    {
      id: 47,
      title: "UNIMUJER-ODAC CIUDAD DELGADO, SAN SALVADOR",
      time: "24/7",
      phone: 22291732,
      direcction:
        "Carretera Troncal del Norte, Km. 4 1/2, Col. Bolívar, Calle Ppal.# 51 Ciudad Delgado, San Salvador.",
      Icon: "PNC.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B042'45.0%22N+89%C2%B010'18.0%22W/@13.7125,-89.1722139,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.7124966!4d-89.171671",
      coordinate: {
        latitude: 13.712497,
        longitude: -89.171671,
      },
      tiposAtencion: `Atención Policial, Auxilio en Crisis, Alojamiento Temporal, Ludoteca`,
    },
    {
      id: 48,
      title: "UNIMUJER-ODAC COJUTEPEQUE, CUSCATLAN",
      time: "24/7",
      phone: 23277618,
      direcction:
        "7a. Av. Sur, Barrio. San Nicolás, Ex - Instituto Cultural, Cojutepeque, Cuscatlán.",
      Icon: "PNC.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B043'16.7%22N+88%C2%B056'20.4%22W/@13.7213056,-88.9395472,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.7212949!4d-88.9389899",
      coordinate: {
        latitude: 13.721295,
        longitude: -88.93899,
      },
      tiposAtencion: `Atención Policial, Auxilio en Crisis, Alojamiento Temporal,Ludoteca`,
    },
    {
      id: 49,
      title: "UNIMUJER-ODAC SUCHITOTO, CUSCATLAN",
      time: "24/7",
      phone: 23351141,
      direcction:
        "4a. Av. Norte y 2a. Cl. Oriente. Local Ex -Museo de la Moneda, Barrio San José, Suchitoto, Cuscatlán.",
      Icon: "PNC.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B056'17.4%22N+89%C2%B001'38.7%22W/@13.9381667,-89.0279639,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.9381653!4d-89.0274239",
      coordinate: {
        latitude: 13.938165,
        longitude: -89.027424,
      },
      tiposAtencion: `Atención Policial, Auxilio en Crisis, Alojamiento Temporal, Ludoteca`,
    },
    {
      id: 50,
      title: "UNIMUJER-ODAC SENSUNTEPEQUE, CABAÑAS",
      time: "24/7",
      phone: 23822219,
      direcction:
        "Final Av. Libertad, Barrio San Antonio, Contiguo Terminal de Buses Sensuntepeque, Cabañas.",
      Icon: "PNC.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B052'40.5%22N+88%C2%B037'37.9%22W/@13.8779167,-88.6277416,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.8779177!4d-88.6272008",
      coordinate: {
        latitude: 13.877918,
        longitude: -88.627201,
      },
      tiposAtencion: `Atención Policial, Auxilio en Crisis, Alojamiento Temporal, Ludoteca`,
    },
    {
      id: 51,
      title: "UNIMUJER-ODAC SAN VICENTE",
      time: "24/7",
      phone: 23936747,
      direcction:
        "Bo. Santuario, 6a. Av. S, # 27, Atrás del Hospital Nacional Santa Gertrudis, San Vicente.",
      Icon: "PNC.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B038'28.0%22N+88%C2%B047'01.7%22W/@13.6411111,-88.7848999,18z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.6411126!4d-88.7838056",
      coordinate: {
        latitude: 13.641113,
        longitude: -88.783806,
      },
      tiposAtencion: `Atención Policial, Auxilio en Crisis, Alojamiento Temporal, Ludoteca`,
    },
    {
      id: 52,
      title: "UNIMUJER-ODAC SAN BARTOLO, ILOPANGO",
      time: "24/7",
      phone: 25291640,
      direcction: "Boulevard San Bartolo, Ilopango, Frente a INSAFORP.",
      Icon: "PNC.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B042'02.1%22N+89%C2%B006'17.6%22W/@13.7006174,-89.1050365,21z/data=!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.7005915!4d-89.1048986",
      coordinate: {
        latitude: 13.700591,
        longitude: -89.104899,
      },
      tiposAtencion: `Atención Policial, Auxilio en Crisis, Alojamiento Temporal, Ludoteca`,
    },
    {
      id: 53,
      title: "UNIMUJER-ODAC LA UNION",
      time: "24/7",
      phone: 26222821,
      direcction: "3a. Calle y 1a. Avenida Sur, Centro de Gobierno, La Unión.",
      Icon: "PNC.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B020'26.7%22N+87%C2%B050'47.0%22W/@13.34075,-87.8469361,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.3407345!4d-87.8463766",
      coordinate: {
        latitude: 13.340735,
        longitude: -87.846377,
      },
      tiposAtencion: `Atención Policial, Auxilio en Crisis, Alojamiento Temporal, Ludoteca`,
    },
    {
      id: 54,
      title: "UNIMUJER-ODAC SANTA ROSA DE LIMA",
      time: "24/7",
      phone: 26412129,
      direcction:
        "Carretera Ruta Militar, Colonia Altos del Estadio, Costado Oriente del Estadio Municipal, Santa Rosa de Lima, La Unión. ",
      Icon: "PNC.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B037'55.4%22N+87%C2%B053'25.7%22W/@13.6320556,-87.8915665,18z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.632047!4d-87.8904843",
      coordinate: {
        latitude: 13.632047,
        longitude: -87.890484,
      },
      tiposAtencion: `Atención Policial, Auxilio en Crisis, Alojamiento Temporal, Ludoteca`,
    },
    {
      id: 55,
      title: "UNIMUJER-ODAC CONCHAGUA, LA UNION",
      time: "24/7",
      phone: 26803365,
      direcction:
        "Calle 15 de Septiembre, Contiguo a Pje. El Tamarindo, Barrio La Cruz, Conchagua, La Unión.",
      Icon: "PNC.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B018'20.3%22N+87%C2%B051'44.9%22W/@13.3056389,-87.8630194,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.3056449!4d-87.8624573",
      coordinate: {
        latitude: 13.305645,
        longitude: -87.862457,
      },
      tiposAtencion: `Atención Policial, Auxilio en Crisis, Alojamiento Temporal, Ludoteca`,
    },
    {
      id: 56,
      title: "UNIMUJER-ODAC JIQUILISCO, USULUTAN",
      time: "24/7",
      phone: 26638816,
      direcction:
        "1a. Calle Pte. casa #10 Barrio San Antonio. Punto de referencia una cuadra hacia abajo del Banco Agrícola Comercial Jiquilisco, Usulután.",
      Icon: "PNC.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B019'39.0%22N+88%C2%B034'16.6%22W/@13.3275,-88.571825,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.3275001!4d-88.571266",
      coordinate: {
        latitude: 13.3275,
        longitude: -88.571266,
      },
      tiposAtencion: `Atención Policial, Auxilio en Crisis, Alojamiento Temporal, Ludoteca`,
    },
    {
      id: 57,
      title: "UNIMUJER-ODAC EL PEDREGAL, ROSARIO DE LA PAZ",
      time: "24/7",
      phone: 23013789,
      direcction:
        "El pedregal, Intersección de la Carretera El Litoral y calle a la Herradura, Rosario de la paz, La Paz. ",
      Icon: "PNC.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B027'48.6%22N+89%C2%B000'35.0%22W/@13.4635,-89.0102694,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.4635087!4d-89.0097066",
      coordinate: {
        latitude: 13.463509,
        longitude: -89.009707,
      },
      tiposAtencion: `Atención Policial, Auxilio en Crisis, Alojamiento Temporal, Ludoteca`,
    },
    {
      id: 58,
      title: "UNIMUJER-ODAC AGUILARES",
      time: "24/7",
      phone: 70736232,
      direcction:
        "8a. Calle Poniente y 5a. Av. Norte, casa sin #, Barrio Guadalupe, Aguilares San Salvador. Punto de referencia contiguo a Subdelegacion Policial.",
      Icon: "PNC.jpg",
      mapURL:
        "https://maps.google.com/maps/place/Policia+Nacional+Civil/@13.9725208,-89.2195627,16z/data=!4m5!3m4!1s0x0:0xdfc0c99eb928f892!8m2!3d13.9734593!4d-89.2176144",
      coordinate: {
        latitude: 13.973757,
        longitude: -89.217633,
      },
      tiposAtencion: `Atención Policial, Auxilio en Crisis, Alojamiento Temporal, Ludoteca`,
    },
    {
      id: 59,
      title: "UNIMUJER-ODAC APOPA",
      time: "24/7",
      phone: 22417904,
      direcction:
        "Col. Madre Tierra 1, Calle Ppal. # 2, Contiguo a Instituto Nacional INA, Apopa, San Salvador. ",
      Icon: "PNC.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B048'16.4%22N+89%C2%B010'55.2%22W/@13.8045556,-89.1825472,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.8045462!4d-89.1819913",
      coordinate: {
        latitude: 13.804546,
        longitude: -89.181991,
      },
      tiposAtencion: `Atención Policial, Auxilio en Crisis, Alojamiento Temporal, Ludoteca`,
    },
    {
      id: 60,
      title: "UNIMUJER-ODAC CIUDAD ARCE, LA LIBERTAD",
      time: "24/7",
      phone: 23309322,
      direcction:
        "3a. Av. Nte. N° 2B, Costado Ote. Del Parque Central, Ciudad Arce, La Libertad. ",
      Icon: "PNC.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B050'32.2%22N+89%C2%B026'45.7%22W/@13.8422778,-89.446575,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.8422814!4d-89.446018",
      coordinate: {
        latitude: 13.842281,
        longitude: -89.446018,
      },
      tiposAtencion: `Atención Policial, Auxilio en Crisis, Alojamiento Temporal, Ludoteca`,
    },
    {
      id: 61,
      title: "UNIMUJER-ODAC SAN JUAN OPICO, LA LIBERTAD",
      time: "24/7",
      phone: 23313035,
      direcction:
        "2a. Calle Oriente y 4a Av. Norte, Barrio el Centro San Juan Opico.",
      Icon: "PNC.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B052'33.2%22N+89%C2%B021'29.7%22W/@13.8758889,-89.3587972,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.8758774!4d-89.35824",
      coordinate: {
        latitude: 13.875877,
        longitude: -89.35824,
      },
      tiposAtencion: `Atención Policial, Auxilio en Crisis, Alojamiento Temporal, Ludoteca`,
    },
    {
      id: 62,
      title: "UNIMUJER-ODAC PUERTO LA LIBERTAD",
      time: "24/7",
      phone: 23277932,
      direcction:
        "Carretera El Litoral, km 34.5 Frente a Malecón Turístico, Puerto de la Libertad. ",
      Icon: "PNC.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B029'15.0%22N+89%C2%B019'13.0%22W/@13.4875,-89.320825,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.4874926!4d-89.320275",
      coordinate: {
        latitude: 13.487493,
        longitude: -89.320275,
      },
      tiposAtencion: `Atención Policial, Auxilio en Crisis, Alojamiento Temporal, Ludoteca`,
    },
    {
      id: 63,
      title: "UNIMUJER-ODAC ZARAGOZA",
      time: "24/7",
      phone: 23140481,
      direcction:
        "Av. Monseñor Romero, Bo. El Centro, Casa 16, Frente a Juzgado de Paz Zaragoza, La Libertad.",
      Icon: "PNC.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B035'16.6%22N+89%C2%B017'14.3%22W/@13.5879444,-89.2878528,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.5879297!4d-89.2872995",
      coordinate: {
        latitude: 13.58793,
        longitude: -89.2873,
      },
      tiposAtencion: `Atención Policial, Auxilio en Crisis, Alojamiento Temporal, Ludoteca`,
    },
    {
      id: 64,
      title: "UNIMUJER-ODAC SANTA TECLA",
      time: "24/7",
      phone: 22287998,
      direcction:
        "4a. Calle Ote. Y Av. Melvin Jones # 2-7, Santa Tecla, La Libertad",
      Icon: "PNC.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B040'19.5%22N+89%C2%B017'04.6%22W/@13.6720833,-89.2851583,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.6720863!4d-89.2846007",
      coordinate: {
        latitude: 13.672086,
        longitude: -89.284601,
      },
      tiposAtencion: `Atención Policial, Auxilio en Crisis, Alojamiento Temporal, Ludoteca`,
    },
    {
      id: 65,
      title: "UNIMUJER-ODAC PARQUE INFANTIL, SAN SALVADOR",
      time: "24/7",
      phone: 25291426,
      direcction:
        "7a. Av. Nte. Entre Alameda Juan Pablo II y 5a Calle Pte.# 418, frente al parque infantil San Salvador.",
      Icon: "PNC.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B042'10.2%22N+89%C2%B011'37.2%22W/@13.7028333,-89.1942139,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.7028312!4d-89.1936585",
      coordinate: {
        latitude: 13.702831,
        longitude: -89.193659,
      },
      tiposAtencion: `Atención Policial, Auxilio en Crisis, Alojamiento Temporal, Ludoteca`,
    },
    {
      id: 66,
      title: "UNIMUJER-ODAC CALLE EL PROGRESO, SAN SALVADOR",
      time: "24/7",
      phone: 25111113,
      direcction:
        "COL. EL ROSAL. CALLE EL PROGRESO, EDIFICIO 2810, S.S., FTE. CENADE.",
      Icon: "PNC.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B042'10.2%22N+89%C2%B011'37.2%22W/@13.7028333,-89.1942139,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.7028312!4d-89.1936585",
      coordinate: {
        latitude: 13.696845,
        longitude: -89.220459,
      },
      tiposAtencion: `Atención Policial, Auxilio en Crisis, Alojamiento Temporal, Ludoteca`,
    },
    {
      id: 67,
      title: "UNIMUJER-ODAC MEJICANOS",
      time: "24/7",
      phone: 24523611,
      direcction:
        "2a. Calle Poniente y Pasaje San Juan, Mejicanos, al Costado Poniente del DUICENTRO.",
      Icon: "PNC.jpg",
      mapURL:
        "https://maps.google.com/maps/place/DUI+Centro+Mejicanos/@13.7222742,-89.190514,21z/data=!4m8!1m2!2m1!1s2a.+calle+poniente+y+pasaje+san+juan+mejicanos+al+costado+poniente+del+duicentro!3m4!1s0x8f63309f4e70e58d:0x16256e3dee442c74!8m2!3d13.7223557!4d-89.1904833",
      coordinate: {
        latitude: 13.7222742,
        longitude: -89.190514,
      },
      tiposAtencion: `Atención Policial, Auxilio en Crisis, Alojamiento Temporal, Ludoteca`,
    },
    {
      id: 68,
      title: "UNIMUJER-ODAC ZACATECOLUCA (PRÓXIMAMENTE)",
      time: "24/7",
      phone: 23341690,
      direcction:
        "Kilómetro 58, Carretera Autopista hacia Usulutan, conocido como la Rotonda, ex chatarrera",
      Icon: "PNC.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B029'51.5%22N+88%C2%B051'46.8%22W/@13.4976389,-88.8651887,17z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.4976407!4d-88.862991?hl=es-419",
      coordinate: {
        latitude: 13.497476,
        longitude: -88.862941,
      },
      tiposAtencion: `Atención Policial, Auxilio en Crisis, Alojamiento Temporal, Ludoteca`,
    },
    {
      id: 69,
      title: "UNIMUJER-ODAC SAN MIGUEL (PRÓXIMAMENTE)",
      time: "24/7",
      phone: 26222701,
      direcction:
        ". Colonia Belén entre 8ª Av. Sur y 10ª Av. Sur y 9ª Calle Oriente Centro de gobierno San Miguel.",
      Icon: "PNC.jpg",
      mapURL:
        "https://maps.google.com/maps/place/Centro+de+Gobierno+San+Miguel/@13.4748767,-88.1729537,17z/data=!4m5!3m4!1s0x8f7b2a6c5eaaaaab:0x6841cdfbbd18dd27!8m2!3d13.4748715!4d-88.170765?hl=es-419",
      coordinate: {
        latitude: 13.475059,
        longitude: -88.170733,
      },
      tiposAtencion: `Atención Policial, Auxilio en Crisis, Alojamiento Temporal, Ludoteca`,
    },
    {
      id: 70,
      title: "UNIMUJER-ODAC SONSONATE (PRÓXIMAMENTE)",
      time: "24/7",
      phone: 24523611,
      direcction:
        "Décima Avenida Sur, Boulevard Oscar Osorio, Contiguo a Cuerpo de Bomberos, Sonsonate.",
      Icon: "PNC.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B042'51.5%22N+89%C2%B043'32.1%22W/@13.7143056,-89.7266776,18z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.714313!4d-89.7255767",
      coordinate: {
        latitude: 13.714313,
        longitude: -89.725577,
      },
      tiposAtencion: `Atención Policial, Auxilio en Crisis, Alojamiento Temporal, Ludoteca`,
    },
    {
      id: 71,
      title: "UNIMUJER-ODAC SAN MARCOS",
      time: "24/7",
      phone: 22207807,
      direcction:
        "Carretera Antigua a Zacatecoluca, Km 5.5, Barrio El Centro, Calle 25 de abril Ote.",
      Icon: "PNC.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B039'25.5%22N+89%C2%B010'55.5%22W/@13.6570833,-89.1826305,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.6570865!4d-89.1820716?hl=es-419",
      coordinate: {
        latitude: 13.657086,
        longitude: -89.182072,
      },
      tiposAtencion: `Atención Policial, Auxilio en Crisis, Alojamiento Temporal, Ludoteca`,
    },
    {
      id: 72,
      title: "UNIMUJER-ODAC ILOBASCO",
      time: "24/7",
      phone: 23322115,
      direcction:
        "4ª Av. Nte Barrio San Miguel Ilobasco Cabañas. Punto de referencia contiguo al Liceo Cristiano El Salvador, punto de autobuses de la ruta 111",
      Icon: "PNC.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B050'42.7%22N+88%C2%B050'56.2%22W/@13.8451944,-88.8494916,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.8451983!4d-88.8489389?hl=es-419",
      coordinate: {
        latitude: 13.845198,
        longitude: -88.848939,
      },
      tiposAtencion: `Atención Policial, Auxilio en Crisis, Alojamiento Temporal, Ludoteca`,
    },
    {
      id: 73,
      title: "UNIMUJER-ODAC MORAZAN",
      time: "24/7",
      phone: 26542132,
      direcction:
        "Final 1ª Calle Pte y 3ª Av. Nte Barrio EL Centro contiguo a Banco Davivienda San Francisco Gotera Morazán",
      Icon: "PNC.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B050'42.7%22N+88%C2%B050'56.2%22W/@13.8451944,-88.8494916,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.8451983!4d-88.8489389?hl=es-419",
      coordinate: {
        latitude: 13.845198,
        longitude: -88.848939,
      },
      tiposAtencion: `Atención Policial, Auxilio en Crisis, Alojamiento Temporal, Ludoteca`,
    },
    {
      id: 74,
      title: "UNIMUJER-ODAC ACAJUTLA",
      time: "24/7",
      phone: 24523611,
      direcction:
        "Final Boulevard Oscar Osorio, Frente a Redondel CEPA, Acajutla Sonsonate.",
      Icon: "PNC.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B035'02.6%22N+89%C2%B049'48.2%22W/@13.5840556,-89.8311499,18z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.5840594!4d-89.8300563",
      coordinate: {
        latitude: 13.584056,
        longitude: -89.830056,
      },
      tiposAtencion: `Atención Policial, Auxilio en Crisis, Alojamiento Temporal, Ludoteca`,
    },

    {
      id: 75,
      title:
        "Juzgado de Instrucción para una Vida Libre de Violencia y Discriminación contra las Mujeres, San Salvador",
      time: "8:00AM - 4:00PM LUN-VIER",
      phone: 22262086,
      direcction:
        "Colonia Médica Avenida Dr. Dimas Funes Hartmann # 20 San Salvador.",
      Icon: "CSJ.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B042'30.1%22N+89%C2%B011'56.7%22W/@13.7083611,-89.2001776,18z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.7083712!4d-89.1990721",
      coordinate: {
        latitude: 13.708371,
        longitude: -89.199072,
      },
      tiposAtencion: `Atención Legal`,
    },
    {
      id: 76,
      title:
        "Juzgado de Instrucción para una Vida Libre de Violencia y Discriminación contra las Mujeres, Santa Ana",
      time: "8:00AM - 4:00PM LUN-VIER",
      phone: 24408353,
      direcction: "12 Avenida Sur Entre 27 y 29 calle poniente # 98, Santa Ana",
      Icon: "CSJ.jpg",
      mapURL:
        "https://maps.google.com/maps/place/13%C2%B058'57.9%22N+89%C2%B033'53.9%22W/@13.98275,-89.5671609,17z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d13.9827584!4d-89.5649762",
      coordinate: {
        latitude: 13.982758,
        longitude: -89.564976,
      },
      tiposAtencion: `Atención Legal`,
    },
    {
      id: 77,
      title:
        "Juzgado de Instrucción para una Vida Libre de Violencia y Discriminación Contra Las Mujeres, San Miguel",
      time: "8:00AM - 4:00PM LUN-VIER",
      phone: 26610822,
      direcction: "8° calle poniente #309 Barrio San Felipe, San Miguel.",
      Icon: "CSJ.jpg",
      mapURL:
        "https://maps.google.com/maps/place/Juzgados+Especializados+de+la+Ni%C3%B1ez+y+Adolescencia/@13.4871757,-88.1902751,17z/data=!4m5!3m4!1s0x0:0x822bfc518cc46182!8m2!3d13.4875397!4d-88.1890362",
      coordinate: {
        latitude: 13.487669,
        longitude: -88.189038,
      },
      tiposAtencion: `Atención Legal`,
    },
  ],
};

export default data;
