import React from "react";
import { useNavigate } from "react-router-dom";
import { AppBar, Toolbar, IconButton, Typography, Box } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Apoya = () => {
  const navigate = useNavigate();

  return (
    <div style={{ minHeight: "100vh",backgroundColor: "#f7f7f7" }}>
      {/* AppBar */}
      <AppBar position="sticky" style={{ backgroundColor: "#59005B" }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
                <Typography
                                            variant="h6"
                                            sx={{
                                              flexGrow: 1
                                              , fontFamily: "'Poppin', 'Helvetica', 'Arial', sans-serif",   letterSpacing: 4 
                                            }}
                                          >
          NOS APOYAN
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Contenido principal */}
      <div
        style={{
          padding: "20px",
          paddingBottom: "80px", // Espacio para la BottomAppBar
          color: "white", // Texto en blanco
        }}
      >
               
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/NosApoyan.jpg`}
            alt="Icono Nos Apoyan"
            style={{
              width: "100%",
              height: "auto",
              marginBottom: "16px",
            }}
          />
          {/* <Typography variant="h5" gutterBottom>
            NOS APOYAN:
          </Typography> */}
        </Box>

       
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "20px 0",
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/CanadaLogo.png`}
            alt="Logo Canadá"
            style={{ maxHeight: "50px", marginRight: "16px" }}
          />
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/UNFPALogo.png`}
            alt="Logo UNFPA"
            style={{ maxHeight: "50px", marginLeft: "16px" }}
          />
        </Box>
        <Typography variant="body1" align="center" gutterBottom>
          Esta aplicación ha sido desarrollada con el apoyo de la iniciativa Spotlight UE-ONU para
          eliminar la violencia contra las mujeres y las niñas.
        </Typography>

       
        <Typography variant="body2" paragraph align="justify">
          La actualización de la APPFEM y su divulgación, ha sido posible con el apoyo del Fondo de
          Población de las Naciones Unidas - UNFPA, auspiciado por el Gobierno de Canadá, para la
          ejecución del proyecto “Fortalecer la promoción, protección y ejercicio de los Derechos
          Sexuales y Derechos Reproductivos, promoviendo la reducción del embarazo en adolescentes
          y la violencia basada en género en Centroamérica”. Su divulgación también ha contado con
          el apoyo de la Entidad de Naciones Unidas para la Igualdad de Género y el Empoderamiento
          de la Mujer, ONU Mujeres, para la ejecución de la Iniciativa Spotlight UE-ONU para
          eliminar la violencia contra las mujeres y las niñas, auspiciada por la Unión Europea.
        </Typography>
        <Typography variant="body2" paragraph align="justify">
          El contenido de esta aplicación es responsabilidad exclusiva de la Red Feminista Frente a
          la Violencia contra las Mujeres (REDFEM), y en ningún caso debe considerarse que refleja
          los puntos de vista de las agencias y organismos donantes.
        </Typography> */}
      </div>
    </div>
  );
};

export default Apoya;
