import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import {
  Button,
  Container,
  createTheme,
  ThemeProvider,
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
} from "@mui/material";
import { Close as CloseIcon, ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import data from "../data";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Configuración del tema
const theme = createTheme({
  palette: {
    primary: { main: "#4b224b" },
    secondary: { main: "#7D0084" },
    background: { default: "#f5f5f5" },
  },
  typography: {
    fontFamily: "'Poppin', 'Helvetica', 'Arial', sans-serif",
  },
});

const NextArrow = ({ onClick }) => (
  <div
    style={{
      position: "absolute",
      top: "50%",
      right: "10px",
      transform: "translateY(-50%)",
      zIndex: 10,
      cursor: "pointer",

    }}
    onClick={onClick}
  >
    <ArrowForwardIos style={{ fontSize: "30px", color: "#FFF" }} />
  </div>
);

const PrevArrow = ({ onClick }) => (
  <div
    style={{
      position: "absolute",
      top: "50%",
      left: "10px",
      transform: "translateY(-50%)",
      zIndex: 10,
      cursor: "pointer",
    }}
    onClick={onClick}
  >
    <ArrowBackIos style={{ fontSize: "30px", color: "#FFF" }} />
  </div>
);

const SlideTransition = React.forwardRef(function SlideTransition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Violencia = () => {
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  // Configuración del carrusel
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 4000,
    slidesToShow: window.innerWidth > 768 ? 2 : 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  // Manejo del Dialog
  const handleDialogOpen = (message) => {
    setDialogMessage(message);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="lg"
        style={{
          padding: "20px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Slider {...settings}>
          {data.tipos_de_violencia.map((tipo, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "20px",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + tipo.url_imagen}
                  alt={tipo.titulo}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "430px",
                    objectFit: "contain",
                  }}
                />
              </div>

              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                  flexWrap: "wrap",
                }}
              >
                 <Button
                  variant="contained"
                  color="primary"
                  style={{
                    width: "100%",
                    maxWidth: "300px",
                    height:"54px",
                    margin: "5px",
                    textTransform: "none",
                    fontFamily: "'Poppin', 'Helvetica', 'Arial', sans-serif",
                    letterSpacing: 7
                  }}
                  onClick={() => handleDialogOpen(tipo.texto)}
                >
                  DEFINICIÓN
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{
                    width: "100%",
                    maxWidth: "300px",
                    height:"54px",
                    margin: "5px",
                    textTransform: "none",
                    fontFamily: "'Poppin', 'Helvetica', 'Arial', sans-serif",
                    letterSpacing: 7
                  }}
                  onClick={() => navigate(`/violencia/${index}/ejemplos`)}
                >
                  EJEMPLOS
                </Button>  
                <Button
                  variant="outlined"
                  color="primary"
                  style={{
                    width: "100%",
                    maxWidth: "300px",
                    color:"#FFF",
                    border:"solid 1px #FFF",
                    height:"54px",
                    margin: "5px",
                    textTransform: "none",
                    fontFamily: "'Poppin', 'Helvetica', 'Arial', sans-serif",
                    letterSpacing: 7
                  }}
                  onClick={() => navigate(`/violencia/${index}/modalidades`)}
                >
                  MODALIDADES
                </Button>
                          
              </div>
            </div>
          ))}
        </Slider>

        {/* Full Dialog */}
        <Dialog
          fullScreen
          open={dialogOpen}
          onClose={handleDialogClose}
          TransitionComponent={SlideTransition}
        >
          <AppBar
            style={{
              position: "relative",
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleDialogClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" style={{ flex: 1, color: "#fff",   fontFamily: "'Poppin', 'Helvetica', 'Arial', sans-serif",
      letterSpacing: 4 }}>
                DEFINICIÓN
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent style={{ padding: "50px" ,  background: "#4b224b",}}>
            <Typography
              variant="h4"
              style={{ color: '#FFF', fontFamily: "'Poppin', 'Helvetica', 'Arial', sans-serif" }}
            >
              {dialogMessage}
            </Typography>
          </DialogContent>
        </Dialog>
      </Container>
    </ThemeProvider>
  );
};

export default Violencia;
