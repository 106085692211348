import React from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Box,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Vinculos = () => {
  const navigate = useNavigate();

  // Listado de vínculos
  const vinculos = [
    {
      name: "RED-FEM",
      description: "Red Feminista Frente a la Violencia contra las Mujeres.",
      logo: "/assets/images/vinculos/REDFEM.png",
      facebook: "https://www.facebook.com/redfemSV",
      website: "https://observatorioseguridadciudadanadelasmujeres.org/",
      
    },
    {
      name: "LAS DIGNAS",
      description: "Asociación de Mujeres por la Dignidad y la Vida.",
      logo: "/assets/images/vinculos/LASDIGNAS.png",
      facebook: "https://www.facebook.com/LasDignasES",
      website: "https://www.lasdignas.org.sv",
      instagram: "https://www.instagram.com/dignaselsalvador/",
    },
    {
      name: "LAS MÉLIDAS",
      description: "Asociación Movimiento de Mujeres Mélida Anaya Montes.",
      logo: "/assets/images/vinculos/LASMELIDAS.png",
      facebook: "https://www.facebook.com/LasMelidassv",
      website: "https://www.lasmelidas.org.sv",
      instagram: "https://www.instagram.com/lasmelidas/",
    },
    {
      name: "ORMUSA",
      description: "Asociación Organización de Mujeres Salvadoreñas por la Paz.",
      logo: "/assets/images/vinculos/ORMUSA.png",
      facebook: "https://www.facebook.com/ormusa.ong",
      website: "https://www.ormusa.org",
      instagram: "https://www.instagram.com/ormusa.ong/",
    },
  ];

  return (
    <div>
      {/* AppBar */}
      <AppBar position="sticky" style={{ backgroundColor: "#59005B" }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" style={{ flexGrow: 1 , fontFamily: "'Poppin', 'Helvetica', 'Arial', sans-serif",   letterSpacing: 4 }}>
          VÍNCULOS DE INTERÉS
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Contenido principal */}
      <div style={{ padding: "20px 20px 80px 20px" }}>
        <Grid container spacing={3}>
          {vinculos.map((vinculo, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                style={{
                  borderRadius: "12px",
                  backgroundColor: "#f4f4f4",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  overflow: "hidden",
                  transition: "transform 0.2s",
                }}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.transform = "scale(1.05)")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.transform = "scale(1)")
                }
              >
                {/* Logo */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#f4f4f4",
                  }}
                >
                  <CardMedia
                    component="img"
                    image={process.env.PUBLIC_URL + vinculo.logo}
                    alt={vinculo.name}
                    style={{
                      width: "140px",
                      height: "auto",
                      objectFit: "contain",
                    }}
                  />
                </Box>
                {/* Información */}
                <CardContent style={{ padding: "16px" }}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{
                      fontWeight: "bold",
                      fontFamily: "'Poppins', sans-serif",
                      textAlign: "center",
                      color: "#333",
                    }}
                  >
                    {vinculo.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{
                      textAlign: "center",
                      fontFamily: "'Poppins', sans-serif",
                      fontSize: "0.9rem",
                      lineHeight: "1.4",
                    }}
                  >
                    {vinculo.description}
                  </Typography>
                </CardContent>
                {/* Botones redondos */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                    paddingBottom: "16px",
                  }}
                >
                  {vinculo.facebook && (
                    <IconButton
                      href={vinculo.facebook}
                      target="_blank"
                      style={{
                        width: "50px",
                        height: "50px",
                        backgroundColor: "#59005B",
                        color: "#fff",
                      }}
                    >
                      {/* Facebook Icon */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                        style={{
                          width: "24px",
                          height: "24px",
                          fill: "currentColor",
                        }}
                      >
                        <path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" />
                      </svg>
                    </IconButton>
                  )}
                  {vinculo.website && (
                    <IconButton
                      href={vinculo.website}
                      target="_blank"
                      style={{
                        width: "50px",
                        height: "50px",
                        backgroundColor: "#59005B",
                        color: "#fff",
                      }}
                    >
                      {/* Website Icon */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        style={{
                          width: "24px",
                          height: "24px",
                          fill: "currentColor",
                        }}
                      >
                        <path d="M352 256c0 22.2-1.2 43.6-3.3 64l-185.3 0c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64l185.3 0c2.2 20.4 3.3 41.8 3.3 64zm28.8-64l123.1 0c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64l-123.1 0c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32l-116.7 0c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0l-176.6 0c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 20.9 58.2 27 94.7zm-209 0L18.6 160C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192l123.1 0c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64L8.1 320C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6l176.6 0c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352l116.7 0zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6l116.7 0z" />
                      </svg>
                    </IconButton>
                  )}
                  {vinculo.instagram && (
                    <IconButton
                      href={vinculo.instagram}
                      target="_blank"
                      style={{
                        width: "50px",
                        height: "50px",
                        backgroundColor: "#59005B",
                        color: "#fff",
                      }}
                    >
                      {/* Instagram Icon */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        style={{
                          width: "24px",
                          height: "24px",
                          fill: "currentColor",
                        }}
                      >
                        <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                      </svg>
                    </IconButton>
                  )}
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default Vinculos;
