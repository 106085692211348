



import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import data from "../data";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
  AppBar,
  Toolbar,
  Box,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LocationOn, AccessTime } from "@mui/icons-material";
import PhoneIcon from "@mui/icons-material/Phone";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const theme = createTheme({
  palette: {
    primary: { main: "#4b224b" },
    secondary: { main: "#59005B" },
    background: { default: "#f5f5f5" },
    text: { primary: "#4b224b" },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});

const ServicioDetalle = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const servicio = data.servicios_de_atencion[id];

  if (!servicio) {
    return (
      <ThemeProvider theme={theme}>
        <Box sx={{ textAlign: "center", padding: "20px" }}>
          <Typography variant="h6" color="error" sx={{ fontFamily: "'Poppins', sans-serif" }}>
            Servicio no encontrado
          </Typography>
        </Box>
      </ThemeProvider>
    );
  }

  const handleCall = (phone) => {
    window.open(`tel:${phone}`, "_self");
  };

  const openWaze = (latitude, longitude) => {
    window.open(
      `https://waze.com/ul?ll=${latitude},${longitude}&navigate=yes`,
      "_blank"
    );
  };

  const openGoogleMaps = (latitude, longitude) => {
    window.open(`https://www.google.com/maps?q=${latitude},${longitude}`, "_blank");
  };

  const openUber = (latitude, longitude) => {
    window.open(
      `https://m.uber.com/ul/?action=setPickup&dropoff[latitude]=${latitude}&dropoff[longitude]=${longitude}`,
      "_blank"
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{  minHeight: "100vh" }}>
        {/* AppBar */}
        <AppBar position="sticky" sx={{ backgroundColor: theme.palette.secondary.main }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => navigate(-1)}
              aria-label="regresar"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography
              variant="h6"
              sx={{ flexGrow: 1, fontFamily: "'Poppin', 'Helvetica', 'Arial', sans-serif",   letterSpacing: 4 }}
            >
              {servicio.titulo}
            </Typography>
          </Toolbar>
        </AppBar>

        {/* Contenido */}
        <Box sx={{ padding: "20px 20px 80px 20px" }}>
          {servicio.items.map((item, index) => (
            <Accordion key={index} sx={{ marginBottom: "15px", borderRadius: "8px" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.secondary.main }} />}
                sx={{
                  backgroundColor: "#fff",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "'Poppins', sans-serif",
                    fontWeight: "500",
                    color: "#595959",
                  }}
                >
                  {item.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <Box sx={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                    <LocationOn sx={{ color: theme.palette.secondary.main, marginRight: "8px" }} />
                    <Typography variant="body1" sx={{ fontFamily: "'Poppins', sans-serif", color:"#595959" }}>
                      {item.direcction}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                    <AccessTime sx={{ color: theme.palette.secondary.main, marginRight: "8px" }} />
                    <Typography variant="body1" sx={{ fontFamily: "'Poppins', sans-serif" , color:"#595959"}}>
                      {item.time}
                    </Typography>
                  </Box>
                  <Box sx={{ textAlign: "center", marginTop: "16px" }}>
                    <IconButton
                      onClick={() => handleCall(item.phone)}
                      sx={{
                        backgroundColor: theme.palette.secondary.main,
                        color: "#fff",
                        width: "50px",
                        height: "50px",
                        marginTop: "10px",
                        marginRight: "10px",
                      }}
                    >
                      <PhoneIcon />
                    </IconButton>
                    <IconButton
                    onClick={() =>
                      openGoogleMaps(
                        item.coordinate.latitude,
                        item.coordinate.longitude
                      )
                    }
                    style={{
                      backgroundColor: theme.palette.secondary.main,
                      color: "#fff",
                      width: "50px",
                      height: "50px",
                      marginTop: "10px",
                      marginRight: "10px",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 488 512"
                      style={{
                        width: "24px",
                        height: "24px",
                        fill: "currentColor",
                      }}
                    >
                      <path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z" />
                    </svg>
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      openWaze(
                        item.coordinate.latitude,
                        item.coordinate.longitude
                      )
                    }
                    style={{
                      backgroundColor: theme.palette.secondary.main,
                      color: "#fff",
                      width: "50px",
                      height: "50px",
                      marginTop: "10px",
                      marginRight: "10px",
                    }}
                  >
                     <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  style={{
                    width: "24px",
                    height: "24px",
                    fill: "currentColor",
                  }} // Tamaño y color adaptados
                >
                  <path d="M502.2 201.7C516.7 287.5 471.2 369.6 389 409.8c13 34.1-12.4 70.2-48.3 70.2a51.7 51.7 0 0 1 -51.6-49c-6.4 .2-64.2 0-76.3-.6A51.7 51.7 0 0 1 159 479.9c-33.9-1.4-58-34.8-47-67.9-37.2-13.1-72.5-34.9-99.6-70.8-13-17.3-.5-41.8 20.8-41.8 46.3 0 32.2-54.2 43.2-110.3C94.8 95.2 193.1 32 288.1 32c102.5 0 197.2 70.7 214.1 169.7zM373.5 388.3c42-19.2 81.3-56.7 96.3-102.1 40.5-123.1-64.2-228-181.7-228-83.5 0-170.3 55.4-186.1 136-9.5 48.9 5 131.4-68.8 131.4C58.2 358.6 91.6 378.1 127 389.5c24.7-21.8 63.9-15.5 79.8 14.3 14.2 1 79.2 1.2 87.9 .8a51.7 51.7 0 0 1 78.8-16.4zM205.1 187.1c0-34.7 50.8-34.8 50.8 0s-50.8 34.7-50.8 0zm116.6 0c0-34.7 50.9-34.8 50.9 0s-50.9 34.8-50.9 0zm-122.6 70.7c-3.4-16.9 22.2-22.2 25.6-5.2l.1 .3c4.1 21.4 29.9 44 64.1 43.1 35.7-.9 59.3-22.2 64.1-42.8 4.5-16.1 28.6-10.4 25.5 6-5.2 22.2-31.2 62-91.5 62.9-42.6 0-80.9-27.8-87.9-64.3z" />
                </svg>
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      openUber(
                        item.coordinate.latitude,
                        item.coordinate.longitude
                      )
                    }
                    style={{
                      backgroundColor: theme.palette.secondary.main,
                      color: "#fff",
                      width: "50px",
                      height: "50px",
                      marginTop: "10px",
                      marginRight: "10px",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      style={{
                        width: "24px",
                        height: "24px",
                        fill: "currentColor",
                      }}
                    >
                      <path d="M414.1 32H33.9C15.2 32 0 47.2 0 65.9V446c0 18.8 15.2 34 33.9 34H414c18.7 0 33.9-15.2 33.9-33.9V65.9C448 47.2 432.8 32 414.1 32zM237.6 391.1C163 398.6 96.4 344.2 88.9 269.6h94.4V290c0 3.7 3 6.8 6.8 6.8H258c3.7 0 6.8-3 6.8-6.8v-67.9c0-3.7-3-6.8-6.8-6.8h-67.9c-3.7 0-6.8 3-6.8 6.8v20.4H88.9c7-69.4 65.4-122.2 135.1-122.2 69.7 0 128.1 52.8 135.1 122.2 7.5 74.5-46.9 141.1-121.5 148.6z" />
                    </svg>
                  </IconButton>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default ServicioDetalle;
