import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./views/Home";
import Violencia from "./views/Violencia";
import Modalidades from "./views/Modalidades";
import Ejemplos from "./views/Ejemplos";
import Oficinas from "./views/Oficinas";
import Servicios from "./views/Servicios";
import ServicioDetalle from "./views/ServicioDetalle";
import LeyDetalle from "./views/LeyDetalle";
import Leyes from "./views/Leyes";
import Info from "./views/Info";
import Multimedia from "./views/Multimedia";
import Vinculos from "./views/Vinculos";
import Informacion from "./views/Informacion";
import Informate from "./views/Informate";
import Apoya from "./views/Apoya";

function App() {
  return (
    <Router basename="/">
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/violencia" element={<Violencia />} />
          <Route path="/violencia/:id/modalidades" element={<Modalidades />} />
          <Route path="/violencia/:id/ejemplos" element={<Ejemplos />} />
          <Route path="/oficinas" element={<Oficinas />} />
          <Route path="/servicios" element={<Servicios />} />
          <Route path="/servicios/:id" element={<ServicioDetalle />} />
          <Route path="/leyes/:id" element={<LeyDetalle />} />
          <Route path="/leyes" element={<Leyes />} />
          <Route path="/multimedia" element={<Multimedia />} />
        <Route path="/vinculos" element={<Vinculos />} />
        <Route path="/informacion" element={<Informacion />} />
        <Route path="/informate" element={<Informate />} />
        <Route path="/apoya" element={<Apoya />} />
          <Route path="/info" element={<Info />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
