import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import data from "../data";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const theme = createTheme({
  palette: {
    primary: { main: "#4b224b" },
    secondary: { main: "#7D0084" },
    background: { default: "#f5f5f5" },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});

const Modalidades = () => {
  const { id } = useParams();
  const tipo = data.tipos_de_violencia[id];
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={theme}>
      <div>
        {/* AppBar */}
        <AppBar position="sticky" sx={{ backgroundColor: theme.palette.primary.main }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="Regresar a la página anterior"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" sx={{ flexGrow: 1, fontFamily: "'Poppin', 'Helvetica', 'Arial', sans-serif",   letterSpacing: 4 }}>
              {tipo.titulo}
            </Typography>
          </Toolbar>
        </AppBar>

        {/* Título */}
        <Box sx={{ padding: "20px"}}>
          <Typography
            variant="h4"
            sx={{
              color: "#FFF",
              fontWeight: "bold",
              fontFamily: "'Poppins', sans-serif",
            }}
          >
            Modalidades
          </Typography>
        </Box>

        {/* Contenido */}
        <Box sx={{ padding: "20px", marginBottom: "60px" }}>
          <Grid container spacing={4}>
            {tipo.modalidades.map((mod, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  sx={{
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                    borderRadius: "8px",
                    textAlign: "center",
                  }}
                >
                  <CardMedia
                    component="img"
                    image={process.env.PUBLIC_URL + "/assets/images/" + mod.url_icono}
                    alt={mod.titulo}
                    sx={{
                      width: 60,
                      height: 60,
                      marginBottom: "15px",
                      borderRadius: "50%",
                    }}
                  />
                  <CardContent sx={{ padding: 0 }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "'Poppins', sans-serif",
                        color: "#4b224b",
                        fontWeight: "bold",
                        marginBottom: "8px",
                      }}
                    >
                      {mod.titulo}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        fontFamily: "'Poppins', sans-serif",
                        color: "#333",
                        lineHeight: "1.5",
                      }}
                    >
                      {mod.texto}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </div>
    </ThemeProvider>
  );
};

export default Modalidades;
// import React from "react";
// import { useParams, useNavigate } from "react-router-dom";
// import data from "../data";
// import { AppBar, Toolbar, IconButton, Typography, Box, Card, CardContent, CardMedia, Grid } from "@mui/material";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// const Modalidades = () => {
//   const { id } = useParams();
//   const tipo = data.tipos_de_violencia[id];
//   const navigate = useNavigate();

//   return (
//     <div style={{ fontFamily: "'Poppins', sans-serif" }}>
//       {/* AppBar */}
//       <AppBar position="sticky" style={{ backgroundColor: '#4b224b' }}>
//         <Toolbar>
//           <IconButton
//             edge="start"
//             color="inherit"
//             aria-label="back"
//             onClick={() => navigate(-1)}
//           >
//             <ArrowBackIcon />
//           </IconButton>
//           <Typography variant="h6" sx={{ flexGrow: 1, fontFamily: "'Poppins', sans-serif" }}>
//            {tipo.titulo}
//           </Typography>
//         </Toolbar>
//       </AppBar>

//       {/* Contenido */}
//       <Box sx={{ padding: "20px", marginBottom:10 }}>
     
//         <Grid container spacing={3}>
//           {tipo.modalidades.map((mod, index) => (
//             <Grid item xs={12} sm={6} md={4} key={index}>
//               <Card sx={{ display: 'flex', alignItems: 'flex-start', padding: '10px' }}>
//                 <CardMedia
//                   component="img"
//                   image={process.env.PUBLIC_URL +'/assets/images/'+ mod.url_icono}
//                   alt={mod.titulo}
//                   sx={{ width: 50, height: 50, marginRight: '20px' }}
//                 />
//                 <CardContent style={{padding:0}}>
//                   <Typography variant="h6" component="div" sx={{ fontFamily: "'Poppins', sans-serif", letterSpacing: 7 }}>
//                     {mod.titulo}
//                   </Typography>
//                   <br/>
//                   <Typography variant="body2" color="text.secondary" sx={{ fontFamily: "'Poppins', sans-serif" }}>
//                     {mod.texto}
//                   </Typography>
//                 </CardContent>
//               </Card>
//             </Grid>
//           ))}
//         </Grid>
//       </Box>
//     </div>
//   );
// };

// export default Modalidades;
