import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Card,
  Grid,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PlayArrowIcon from "@mui/icons-material/PlayArrow"; // Icono de Play
import CloseIcon from "@mui/icons-material/Close";
const Multimedia = () => {
  const navigate = useNavigate();

  const [openVideoDialog, setOpenVideoDialog] = useState(false);
  const [videoSrc, setVideoSrc] = useState("");
  const [videoTitle, setVideoTitle] = useState("");
  const [videoDescription, setVideoDescription] = useState("");

  // Listado de vínculos
  const vinculos = [   
    {
      title: "Generalidades de la LEIV",
      description:
        "En este espacio conocerás sobre las partes más importantes que contiene la LEIV e.g. derechos, principios y conceptos.",
      videoUrl: "videos/VIDEOONE.mp4", // Ruta del video dentro de la carpeta assets/videos
    },
    {
      title: "Tipos de Violencia",
      description:
        "Conocerás a través de ejemplos los tipos de violencia: psicológica y emocional, física, sexual, feminicida, económica, patrimonial & simbólica.",
      videoUrl: "videos/VIDEOTWO.mp4", // Ruta del video dentro de la carpeta assets/videos
    },
    {
      title: "Adónde Acudir",
      description:
        "Conocerás cuales instituciones tienen UIAEM así como el procedimiento a seguir para ser atendida.",
      videoUrl: "videos/VIDEOTHREE.mp4", // Ruta del video dentro de la carpeta assets/videos
    },
    {
      title: "Ruta de la Denuncia",
      description:
        "En caso de violencia sexual esta es la ruta que debes seguir para presentar una denuncia en las instituciones pertinentes.",
      videoUrl: "videos/VIDEOFOUR.mp4", // Ruta del video dentro de la carpeta assets/videos
    },
  ];

  const handlePlayVideo = (videoUrl, title, description) => {
    setVideoSrc(process.env.PUBLIC_URL + "/assets/" + videoUrl); // Obtener la ruta completa del video
    setVideoTitle(title);
    setVideoDescription(description);
    setOpenVideoDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenVideoDialog(false);
  };

  return (
    <div>
      {/* AppBar */}
      <AppBar position="sticky" style={{ backgroundColor: "#59005B" }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => navigate(-1)}>
          <ArrowBackIcon />
          </IconButton>
         <Typography
                      variant="h6"
                      sx={{
                        flexGrow: 1
                        , fontFamily: "'Poppin', 'Helvetica', 'Arial', sans-serif",   letterSpacing: 4 
                      }}
                    >
          ARCHIVOS MULTIMEDIA
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Contenido principal */}
      <div style={{ padding: "20px 20px 80px 20px" }}>
        <Grid container spacing={3}>
          {vinculos.map((vinculo, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                style={{
                  backgroundColor: "#fff", // Fondo blanco
                  color: "#6a4c9c", // Texto morado
                  borderRadius: "12px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  overflow: "hidden",
                  textAlign: "center",
                  padding: "20px",
                  transition: "transform 0.2s",
                }}
                onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.05)")}
                onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
              >
                {/* Título */}
                <Typography variant="h5" style={{ fontWeight: "bold" }}>
                  {vinculo.title}
                </Typography>
                {/* Descripción */}
                <Typography variant="body2" style={{ marginTop: "10px" }}>
                  {vinculo.description}
                </Typography>

                {/* Botón redondo de Play y texto "Ver Video" */}
                <Box
                  sx={{
                    marginTop: "20px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      borderRadius: "50%",
                      width: "60px",
                      height: "60px",
                      minWidth: "auto",
                      padding: "0",
                      backgroundColor: "#6a4c9c", // Color morado para el botón
                    }}
                    onClick={() => handlePlayVideo(vinculo.videoUrl, vinculo.title, vinculo.description)}
                  >
                    <PlayArrowIcon style={{ fontSize: "30px", color: "#fff" }} />
                  </Button>
                  <Typography variant="body2" style={{ marginTop: "10px" }}>
                    Ver Video
                  </Typography>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>

      {/* Diálogo para ver el video en pantalla completa */}
      <Dialog
        open={openVideoDialog}
        onClose={handleCloseDialog}
        fullScreen
        maxWidth="lg"
        aria-labelledby="video-dialog-title"
        PaperProps={{
          style: {
            backgroundColor: "#FFF", // Fondo blanco para el modal
          },
        }}
      >
        <DialogActions style={{ justifyContent: "flex-end", backgroundColor: "#59005B" }}>
          <Button
            onClick={handleCloseDialog}
            color="primary"
            style={{ backgroundColor: "#fff", color: "#59005B" }}
          >
             <CloseIcon style={{ marginRight: "8px" }} />Cerrar
          </Button>
        </DialogActions>

        <DialogContent>
          {/* Video */}
          <video
            src={videoSrc}
            controls
            autoPlay
            style={{ width: "100%", height: "auto", backgroundColor: "#fff" }}
          />

          {/* Título y descripción debajo del video */}
          <Box style={{ textAlign: "center", marginTop: "20px" }}>
            <Typography variant="h5" style={{ fontWeight: "bold", color: "#6a4c9c" }}>
              {videoTitle}
            </Typography>
            <Typography variant="body1" style={{ marginTop: "10px", color: "#6a4c9c" }}>
              {videoDescription}
            </Typography>
          </Box>
           {/* Sección de texto e imagen al final de todas las cards */}
      <Box>
        {/* Imagen a la izquierda */}
        <Box sx={{ margin: "8px", marginTop:"32px" }}>
          <img
            src={process.env.PUBLIC_URL + "/assets/images/UTEDis.png"} // Ruta de la imagen
            alt="LESSA y UTE"
            style={{ width: "100%", borderRadius: "8px" }}
          />
        </Box>
       
      </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Multimedia;
