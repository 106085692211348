import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowBack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"; // Icono para expandir el acordeón

const Informate = () => {
  const [expanded, setExpanded] = useState(false);
  const [expandedInternal, setExpandedInternal] = useState(null); // State for internal accordions
  const navigate = useNavigate();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleInternalAccordionChange = (panel) => (event, newExpanded) => {
    setExpandedInternal(newExpanded ? panel : null); // Set state for internal accordion
  };

  return (
    <div style={{ minHeight: "100vh", backgroundColor: "#f7f7f7" }}>
      {/* AppBar */}
      <AppBar position="sticky" style={{ backgroundColor: "#59005B" }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => navigate(-1)}>
            <ArrowForwardIcon />
          </IconButton>
          <Typography
            variant="h6"
            sx={{
              flexGrow: 1,
              fontFamily: "'Poppin', 'Helvetica', 'Arial', sans-serif",
              letterSpacing: 4,
            }}
          >
            INFÓRMATE
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Contenido principal */}
      <div
        style={{
          padding: "20px",
          paddingBottom: "80px",
          backgroundColor: "#f7f7f7",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "left",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/BannerFYI.png`}
              alt="Icono Nos Apoyan"
              style={{
                width: "90%",
                height: "auto",
                marginBottom: "32px",
              }}
            />
          </Box>

          {/* Acordeón de información */}
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography variant="h6" style={{ color: "#6a4c9c" }}>
                ¿Debes pagar por los servicios de atención que brindan las
                instituciones públicas?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" style={{ color: "#6a4c9c" }}>
                Los servicios de atención en salud, deben ser brindados de forma
                gratuita. (art. 26 letra a de la Ley de Igualdad, Equidad y
                Erradicación de la Discriminación contra las Mujeres-LIE y Art.
                66 de la Constitución de la República.
                <br /> <br />
                El acceso a la Justicia, debe ser gratuita, según el artículo
                181 de la Constitución de la República. <br /> <br />
                La Procuraduría General de la República-PGR, brinda servicios de
                asistencia legal gratuitos, en (art. 194 romano II ordinal 2do
                de la Constitución de la República, Ley Orgánica de la
                Procuraduría General de la Republica, relacionados con el art.
                6, letra a) de la ley de Ética Gubernamental.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography variant="h6" style={{ color: "#6a4c9c" }}>
                ¿Pueden negarme servicios en razón de mi religión o creencias?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" style={{ color: "#6a4c9c" }}>
                Las personas servidoras públicas no pueden denegarte la
                prestación de un servicio público al que tengas derecho, en
                razón de tu nacionalidad, raza, sexo, religión, opinión
                política, condición social o económica, discapacidad o cualquier
                otra razón injustificada (Art. 6, letra “j” de la Ley de Ética
                Gubernamental).
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
            >
              <Typography variant="h6" style={{ color: "#6a4c9c" }}>
                ¿Qué puedes hacer si no te atienden?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" style={{ color: "#6a4c9c" }}>
                Todas las instituciones públicas, como PNC, PGR y MINSAL, tienen
                la obligación legal de atenderte en casos de violencia contra
                las mujeres, en caso que no lo hagan, o no estés satisfecha con
                los servicios, puedes acudir a las siguientes instituciones para
                recibir asesoría completamente gratuita sobre qué hacer:
                <br /> <br />
              </Typography>

              {/* Sub acordeón */}
              <Accordion
                expanded={expandedInternal === "subPanel1"}
                onChange={handleInternalAccordionChange("subPanel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="subPanel1-content"
                  id="subPanel1-header"
                >
                  <Typography variant="subtitle2" style={{ color: "#6a4c9c" }}>
                    Procuraduría de Derechos Humanos.
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" style={{ color: "#6a4c9c" }}>
                    Puedes poner una denuncia que tramitará la institución.{" "}
                    <br />
                    <br />
                    Es requisito que te hayan negado el servicio e identificarte
                    con Documento Único de Identidad.
                    <ul>
                      <li>Consultas al Teléfono: 25204300</li>
                      <li>Denuncias al Teléfono: 25204331</li>
                      <li>
                        Dirección: 5ta. Ave. Nte., y 19 Calle Pte. No. 12,
                        Centro de Gobierno, San Salvador
                      </li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expandedInternal === "subPanel2"}
                onChange={handleInternalAccordionChange("subPanel2")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="subPanel2-content"
                  id="subPanel2-header"
                >
                  <Typography variant="subtitle2" style={{ color: "#6a4c9c" }}>
                    Tribunal de Ética Gubernamental.
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" style={{ color: "#6a4c9c" }}>
                    Puedes poner una denuncia que tramitara la institución, como
                    requisito es necesario que te hayan negado el servicio e
                    identificarte con Documento Único de Identidad.
                    <br /> <br />
                    Página Web:{" "}
                    <a
                      href="https://teg.gob.sv/denuncias/#.XaXFX0YzbIU"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      https://teg.gob.sv/denuncias/#.XaXFX0YzbIU
                    </a>
                    <br /> <br />
                    <b>CONTACTOS EN SEDE CENTRAL:</b> <br />
                    Lic. William Rivera; willian.rivera@teg.gob.sv, <br />
                    Lic. David Cruz; d.cruz@teg.gob.sv. <br />
                    Teléfono: 2565-9436 y 2565-9438. <br /> <br />
                    <b>CONTACTO EN SEDE EN SAN MIGUEL:</b> <br />
                    Licda. Roxana Sánchez; r.sanchez@teg.gob.sv. <br />
                    Teléfono: 2605-1424.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expandedInternal === "subPanel3"}
                onChange={handleInternalAccordionChange("subPanel3")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="subPanel3-content"
                  id="subPanel3-header"
                >
                  <Typography variant="subtitle2" style={{ color: "#6a4c9c" }}>
                    Fundación Nacional para el Desarrollo.
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" style={{ color: "#6a4c9c" }}>
                    Página web:{" "}
                    <a
                      href="http://alac.funde.org/start"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      http://alac.funde.org/start
                    </a>
                    <br /> <br />
                    <b>CENTRO DE ASESORÍA LEGAL ANTICORRUPCIÓN.</b> <br />
                    Teléfonos: 2209-5324, 7987-1688. <br />
                    Correo electrónico: alac@funde.org. <br />
                    Dirección: Calle Arturo Ambrogi No. 411, Col. Escalón. San
                    Salvador, El Salvador, C.A.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expandedInternal === "subPanel4"}
                onChange={handleInternalAccordionChange("subPanel4")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="subPanel4-content"
                  id="subPanel4-header"
                >
                  <Typography variant="subtitle2" style={{ color: "#6a4c9c" }}>
                    Si acudiste a la Procuraduría General de la República (PGR).
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" style={{ color: "#6a4c9c" }}>
                    La PGR a través de la Unidad de Atención Especializada para
                    Mujeres, tiene como mandato brindar servicios de asistencia
                    legal, psicológica y social a mujeres que enfrentan hechos
                    de violencia intrafamiliar, de género o discriminación.
                    <br />
                    <br />
                    Los servicios son totalmente gratuitos y si existiere algún
                    cobro, puedes reportarlo al siguiente número de whatsapp:
                    76079013.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4-content"
              id="panel4-header"
            >
              <Typography variant="h6" style={{ color: "#6a4c9c" }}>
                ¿Cuáles son los requisitos para ingresar al albergue temporal
                del ISDEMU?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" style={{ color: "#6a4c9c" }}>
                Los albergues son servicios de alojamiento de emergencia y
                temporales, es decir, que duran un periodo determinado y se
                ofrecen a mujeres que están enfrentando violencia y se
                encuentran en una grave situación de riesgo.
                <br /> <br />
              </Typography>

              {/* Sub acordeón */}
              <Accordion
                expanded={expandedInternal === "subPanel5"}
                onChange={handleInternalAccordionChange("subPanel5")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="subPanel5-content"
                  id="subPanel5-header"
                >
                  <Typography variant="subtitle2" style={{ color: "#6a4c9c" }}>
                    ¿Cuánto tiempo dura?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" style={{ color: "#6a4c9c" }}>
                    Se termina según el caso y la emergencia que presenta, para
                    esto hay un equipo que da seguimiento a cada caso.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expandedInternal === "subPanel6"}
                onChange={handleInternalAccordionChange("subPanel6")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="subPanel6-content"
                  id="subPanel6-header"
                >
                  <Typography variant="subtitle2" style={{ color: "#6a4c9c" }}>
                    ¿Aplica solo para mujeres?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" style={{ color: "#6a4c9c" }}>
                    Sí, pero solo mujeres cuya vida esté en peligro por
                    violencia basada en su género. También puede brindarse
                    alojamiento a las hijas e hijos de la madre víctima, para
                    niños menores de 9 años.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expandedInternal === "subPanel7"}
                onChange={handleInternalAccordionChange("subPanel7")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="subPanel7-content"
                  id="subPanel7-header"
                >
                  <Typography variant="subtitle2" style={{ color: "#6a4c9c" }}>
                    ¿Qué documentos necesitas?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" style={{ color: "#6a4c9c" }}>
                    En el albergue de ISDEMU necesitas identificarte de
                    preferencia con Documento Único de Identidad, pero si no lo
                    tienes, no hay problema, evaluarán el caso y te pueden
                    brindar asistencia sin el DUI u otro documento, pero
                    recuerda, solo en casos donde peligre tu vida o la de tus
                    hijas.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expandedInternal === "subPanel8"}
                onChange={handleInternalAccordionChange("subPanel8")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="subPanel8-content"
                  id="subPanel8-header"
                >
                  <Typography variant="subtitle2" style={{ color: "#6a4c9c" }}>
                    ¿Cuántos albergues existen?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" style={{ color: "#6a4c9c" }}>
                    En el caso de ISDEMU, solo hay uno, pero según las
                    características de cada caso y la emergencia, las
                    instituciones pueden coordinar con otros albergues
                    temporales de organizaciones sociales.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expandedInternal === "subPanel9"}
                onChange={handleInternalAccordionChange("subPanel9")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="subPanel9-content"
                  id="subPanel9-header"
                >
                  <Typography variant="subtitle2" style={{ color: "#6a4c9c" }}>
                    ¿Hay restricciones?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" style={{ color: "#6a4c9c" }}>
                    En el caso de mujeres que enfrentan violencia basada en su
                    género, no hay restricciones de edad o de otra índole, el
                    requisito es que esté en situación de grave riesgo de su
                    vida y sus hijas e hijos. Sin embargo, si hay una
                    restricción en casos de permanencia de niños de más de 9
                    años, ya que en el albergue puede haber otras mujeres con
                    niñas y niños alojados.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expandedInternal === "subPanel10"}
                onChange={handleInternalAccordionChange("subPanel10")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="subPanel10-content"
                  id="subPanel10-header"
                >
                  <Typography variant="subtitle2" style={{ color: "#6a4c9c" }}>
                    ¿Cuáles son los lineamientos para albergue temporal en
                    ISDEMU?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" style={{ color: "#6a4c9c" }}>
                    Brindar protección a mujeres que enfrentan VIOLENCIA BASADA
                    EN GÉNERO en riesgo extremo.
                    <br />
                    <br />
                    Proporcionar un espacio temporal seguro en el que se brinde
                    una atención de calidad y calidez para que la mujer que ha
                    enfrentado VIOLENCIA BASADA EN GÉNERO reflexione con apoyo
                    de las profesionales sobre las alternativas para enfrentar
                    la situación en la que se encuentra.
                    <br />
                    <br />
                    Priorizar la integridad física, psicológica y sexual de la
                    mujer y de sus hijas e hijos.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expandedInternal === "subPanel11"}
                onChange={handleInternalAccordionChange("subPanel11")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="subPanel11-content"
                  id="subPanel11-header"
                >
                  <Typography variant="subtitle2" style={{ color: "#6a4c9c" }}>
                    ¿Cuáles son los requisitos para que una mujer pueda ingresar
                    en el albergue?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" style={{ color: "#6a4c9c" }}>
                    Obligatoriamente, debe ser una mujer que enfrenta riesgo
                    extremo de VIOLENCIA BASADA EN GÉNERO.
                    <br />
                    <br />
                    Mujer que no posea redes de apoyo.
                    <br />
                    <br />
                    Mujer con problemas de salud, siempre y cuando su condición
                    no limite e impida realizar sus funciones cotidianas o con
                    capacidades especiales que no le dificulten su movilización.
                    <br />
                    <br />
                    Mujer que acepte ingresar voluntariamente, apegándose a las
                    normas de convivencia establecidas por las autoridades del
                    albergue y con la disposición de compartir el espacio con
                    mujeres con características similares.
                    <br />
                    <br />
                    Mujer con hijos menores a nueve años de edad; en el caso de
                    las hijas, no hay una edad límite.
                    <br />
                    <br />
                    El ingreso de mujeres que tengan problemas para relacionarse
                    o que pongan en riesgo a las otras personas que se
                    encuentren albergadas será analizado por la Jefatura del
                    Albergue y por la Directora Ejecutiva de ISDEMU.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expandedInternal === "subPanel12"}
                onChange={handleInternalAccordionChange("subPanel12")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="subPanel12-content"
                  id="subPanel12-header"
                >
                  <Typography variant="subtitle2" style={{ color: "#6a4c9c" }}>
                    ¿Hay restricciones?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" style={{ color: "#6a4c9c" }}>
                    En el caso del albergue de ISDEMU y para garantizar la
                    seguridad de las mujeres y de las técnicas del albergue son
                    las siguientes:
                    <br />
                    <br />
                    <ul>
                      <li>
                        Mujeres que no presenten una situación de riesgo extremo
                        de VIOLENCIA BASADA EN GÉNERO.
                      </li>
                      <li>
                        Mujeres que presenten enfermedades o trastornos
                        psiquiátricos (se encuentren en tratamiento o no).
                      </li>
                      <li>
                        Mujeres que no acepten las normas de convivencia del
                        albergue.
                      </li>
                      <li>
                        Mujeres que no enfrenten VIOLENCIA BASADA EN GÉNERO,
                        pero que necesiten cubrir sus necesidades básicas.
                      </li>
                      <li>
                        Mujeres que soliciten albergue, pero que representen
                        riesgo para otras mujeres que estén ingresadas.
                      </li>
                      <li>
                        Mujeres que presenten una situación de riesgo extremo de
                        VIOLENCIA BASADA EN GÉNERO, pero que cuenten con una red
                        de apoyo funcional.
                      </li>
                      <li>
                        Mujeres que estén afrontando una enfermedad terminal y
                        necesiten de cuidados especializados referentes a su
                        salud.
                      </li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expandedInternal === "subPanel13"}
                onChange={handleInternalAccordionChange("subPanel13")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="subPanel13-content"
                  id="subPanel13-header"
                >
                  <Typography variant="subtitle2" style={{ color: "#6a4c9c" }}>
                    ¿Qué debes hacer si un servidor o funcionario público
                    (médico, policía, juez, etc.) te acosa sexualmente?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" style={{ color: "#6a4c9c" }}>
                    Puedes denunciarlo en la UNIMUJER ODAC de la PNC o de la
                    FGR, o pedir asesoría en el Tribunal de Ética Gubernamental.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expandedInternal === "subPanel14"}
                onChange={handleInternalAccordionChange("subPanel14")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="subPanel14-content"
                  id="subPanel14-header"
                >
                  <Typography variant="subtitle2" style={{ color: "#6a4c9c" }}>
                    ¿Qué hacer si te niegan atención en un centro de salud o te
                    han atendido mal?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" style={{ color: "#6a4c9c" }}>
                    El derecho a la salud es un mandato constitucional y el
                    Estado Salvadoreño está obligado a brindar este servicio, el
                    cual debe prestarse en condiciones adecuadas y con un trato
                    digno. Si te lo han negado o consideras que no te han
                    atendido dignamente, puedes denunciarlo al Tribunal de Ética
                    Gubernamental, también a la FUNDE o, si es un caso donde has
                    sido víctima de violencia sexual, denunciar también en las
                    unidades UNIMUJER de la Policía Nacional Civil.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </AccordionDetails>
          </Accordion>
        </Box>
      </div>
    </div>
  );
};

export default Informate;
